import React from 'react';
import Snackbar from '@mui/material/Snackbar';

export const ErrorSnackbar = ({ open, errMsg, onClose }) => {
    return (<Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        message={errMsg}
    />);
}