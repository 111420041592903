import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';

function PaymentBanner({ isFreemium }) {
  const navigate = useNavigate();
  const navigateToPayment = () => {
    navigate('account/billing');
  }

  return (
    <div style={{fontFamily: "Roboto"}}>
      {isFreemium && <Alert severity="warning">A plan subscription is needed before activating an interview.{" "} 
      <button className="text-button"
        style={{ fontFamily: "Roboto", fontSize: "15px", fontWeight: "500", color: "#00B897", cursor: "pointer" }}
        onClick={() => navigateToPayment()}>
        Payment setup
      </button>
      </Alert>}
    </div>);
}

export default PaymentBanner;