export function ConstructProjectDetailsForNewProject() {
    return constructProjectDetails("", "", "", 0);
}

export function ConstructProjectDetails(p) {
    return constructProjectDetails(
        p.projectDetails.productOverview,
        p.projectDetails.interviewGoal,
        p.projectDetails.budget);
}

function constructProjectDetails(pOverview, iGoal, budget) {
    return {
        productOverview: pOverview,
        interviewGoal: iGoal,
        budget: budget,
    }
}