const BASE_DELAY_MS = 100
const RETRIABLE_ERROR_CODES = ["functions/internal", "functions/deadline-exceeded", "functions/resource-exhausted", "functions/unavailable", "functions/aborted"];
const MAX_ATTEMPT = 3;

export const RetryWithExponentialBackoff = async (fn, fnParams) => {
    let attempt = 1;
    const execute = async () => {
        try {
            console.log("Attempt: " + attempt);
            return await fn(fnParams);
        } catch (error) {
            if (attempt >= MAX_ATTEMPT) {
                throw error;
            }
            const delayMs = BASE_DELAY_MS * 2 ** attempt;

            if (error.name === "FirebaseError") {
                console.log("Error response is: " + error.code);
                console.log("Error response status is: " + error.message);

                if (error.code && RETRIABLE_ERROR_CODES.includes(error.code)) {
                    console.log(`Retry attempt ${attempt} after ${delayMs}ms`);
                    await new Promise((resolve) => setTimeout(resolve, delayMs));
                    attempt++;
                    return execute();
                } else {
                    throw error;
                }
            } else {
                throw error;
            }
        };
    }
    return execute();
}