import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import '../../pages/ResearchProject.css';

export const PermanentDialogue = ({ open, title, content }) => {
    return (<Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
        </DialogContent>
    </Dialog>);
}

export const ActionDialogue = ({ open, title, content, cancel, confirm, cancelText, confirmText }) => {
    return (<Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: "30px" }} 
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "0 20px 20px 0", gap: "20px" }}>
            <button className="text-button" style={{ color: '#666' }} onClick={cancel} autoFocus>{cancelText}</button>
            <button className="text-button" style={{ color: '#00B897' }} onClick={confirm}>{confirmText}</button>
        </DialogActions>
    </Dialog>);
}


export const ActionDialogueAlternate = ({ open, title, content, cancel, confirm, cancelText, confirmText }) => {
    return (<Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: "30px" }} 
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "0 20px 20px 0", gap: "20px" }}>
            <button className="text-button" style={{ color: '#666' }} onClick={cancel}>{cancelText}</button>
            <button className="text-button" style={{ color: '#D15252' }} onClick={confirm}>{confirmText}</button>
        </DialogActions>
    </Dialog>);
}

