import { useEffect, useState } from "react";
import { ProductOverview, InterviewGoal, Budget } from "../researchproject/InputFields.js";
import { initiateTestInterviewInANewTab } from "../interview/ChatInterviewUtils.js";
import { Instructions } from "./Instructions.js";
import { ConstructProjectDetails, ConstructProjectDetailsForNewProject } from "./ResearchProjectUtil.js";
import { DeleteResearchProject, SaveResearchProject, UpdateResearchProject } from "../../api/Firestore.js";
import { useNavigate } from "react-router-dom";
import { ActionDialogueAlternate } from "../common/Dialogue.js";
import Tooltip from '@mui/material/Tooltip';


function ProjectConfig({ projectId, savedProject, status, updateSavedProject }) {

    const navigate = useNavigate();

    const [projectDetails, setProjectDetails] = useState({});
    const [deleteDialogueOpen, setDeleteDialogueOpen] = useState(false);

    const getProductOverview = (data) => setProjectDetails({ ...projectDetails, productOverview: data });
    const getInterviewGoal = (data) => setProjectDetails({ ...projectDetails, interviewGoal: data });
    const getBudget = (data) => {
        setProjectDetails({ ...projectDetails, budget: data })
    };

    useEffect(() => {
        if (savedProject !== null) {
            setProjectDetails(ConstructProjectDetails(savedProject));
        } else {
            setProjectDetails(ConstructProjectDetailsForNewProject());
        }
    }, [savedProject]);

    const isProductOverviewDefined = () => {
        return (projectDetails.productOverview !== undefined) && (projectDetails.productOverview !== "");
    }
    const isInterviewGoalDefined = () => {
        return (projectDetails.interviewGoal !== undefined) && (projectDetails.interviewGoal !== "");
    }

    const canTestInterview = () => {
        return isProductOverviewDefined() || isInterviewGoalDefined();
    }
    const testInterview = () => {
        initiateTestInterviewInANewTab(projectId, projectDetails.productOverview, projectDetails.interviewGoal);
    }

    const canSaveChanges = () => {
        if (savedProject === null && (isProductOverviewDefined() || isInterviewGoalDefined())) {
            return true;
        } else if (savedProject !== null &&
            (projectDetails.productOverview !== savedProject.projectDetails.productOverview ||
                projectDetails.interviewGoal !== savedProject.projectDetails.interviewGoal ||
                projectDetails.budget !== savedProject.projectDetails.budget)) {
            return true;
        }
        return false;
    }
    const saveChanges = async () => {
        try {
            // First time we're creating this project
            if (savedProject === null) {
                // Check if there's a title saved
                const updatedProject = await SaveResearchProject(projectId,
                    "", // there's no name
                    projectDetails.productOverview,
                    projectDetails.interviewGoal,
                    projectDetails.budget);
                updateSavedProject(updatedProject);
            } else {
                // Updating an existing project
                const updatedProject = await UpdateResearchProject(
                    savedProject,
                    status,
                    projectDetails.productOverview,
                    projectDetails.interviewGoal,
                    projectDetails.budget,
                );
                updateSavedProject(updatedProject);
            }
        } catch (err) {
            console.log("Unable to save changes: " + err);
        }
    }

    const handleDeleteDialogOpen = () => {
        setDeleteDialogueOpen(true);
    };

    const handleDeleteDialogCancel = () => {
        setDeleteDialogueOpen(false);
    };

    const deleteProject = async () => {
        if (savedProject === null) {
            navigate("/home");
            return;
        }
        try {
            await DeleteResearchProject(projectId, savedProject.projectDetails.interviewLinkId);
            navigate("/home");
        } catch (err) {
            console.log("Couldn't delete project: " + err);
        }
    }

    return (
        <div className="container">
            <ActionDialogueAlternate open={deleteDialogueOpen}
                title="Permanently delete project?"
                content="Are you sure you want to permanently delete this project? This action cannot be undone."
                cancel={handleDeleteDialogCancel}
                confirm={deleteProject}
                cancelText="Cancel"
                confirmText="Delete Project"
            />
            <Instructions />

            <div className="configuration-title">
                <h2>Interview Configuration</h2>
                <button className='custom-button'
                    onClick={saveChanges}
                    disabled={!canSaveChanges()}>
                    Save changes
                </button>
            </div>
            <div className='configuration-inputs'>
                <div style={{ flex: "1" }}>
                    <ProductOverview defaultValue={projectDetails.productOverview} productOverview={getProductOverview} />
                </div>
                <div style={{ flex: "1" }}>
                    <InterviewGoal defaultValue={projectDetails.interviewGoal} interviewGoal={getInterviewGoal} />
                </div>
            </div>
            <Tooltip title="Tests the interview agent based on the content you've entered above, regardless of whether you've saved the changes">
                <span>
                    <button className="text-button"
                        disabled={!canTestInterview()}
                        onClick={testInterview}>
                        Test Interview
                    </button>
                </span>
            </Tooltip>
            <Budget defaultValue={projectDetails.budget}
                budget={getBudget}
                existingUserInterviewCount={(savedProject === null) ? 0 : savedProject.userInterviewCount} />

            <button className='text-button'
                style={{ color: "#D15252", margin: "3rem 0 2rem 0" }}
                onClick={handleDeleteDialogOpen}>
                Delete Project
            </button>
        </div>
    );
}
export default ProjectConfig;