// Import the functions you need from the SDKs you need
import { doc, setDoc, collection, addDoc, getDocs, getDoc, onSnapshot, runTransaction, updateDoc, query, orderBy } from "firebase/firestore";
import { getFirebaseFirestore, getFirebaseAuth } from '../components/firebase/firebase';
import { getSubscriptionState } from "../components/stripe/subscriptions";

const auth = getFirebaseAuth();
const db = getFirebaseFirestore();

/** Research Project Access/Storage **/

export async function GetResearchProject(projectId) {
  const docRef = doc(db, "customers", auth.currentUser.uid, "projects", projectId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function ListResearchProjects() {
  var projects = [];
  const querySnapshot = await getDocs(collection(db, "customers/" + auth.currentUser.uid + "/projects"));
  querySnapshot.forEach((doc) => {
    projects.push({
      projectId: doc.id,
      projectDetails: doc.data().projectDetails,
      userInterviewCount: doc.data().userInterviewCount,
    });
  });
  return projects;
}

export function CreateResearchProjectId() {
  const docRef = doc(collection(db, "customers", auth.currentUser.uid, "projects"));
  return docRef.id;
}

export async function SaveResearchProject(projectId, projectName, productOverview, interviewGoal, budget) {
  var interviewLinkDocRef = doc(collection(db, "interviewlinks"));
  var projectDocRef = doc(db, "customers", auth.currentUser.uid, "projects", projectId);

  var savedProject = {
    accountId: auth.currentUser.uid,
    projectId: projectId,
    projectDetails: {
      projectName: projectName,
      productOverview: productOverview,
      interviewGoal: interviewGoal,
      budget: budget,
      status: "draft",
      interviewLinkId: interviewLinkDocRef.id,
      timestamp: Date.now(),
    }
  }

  await runTransaction(db, async (transaction) => {
    try {
      transaction.set(projectDocRef, savedProject);
      transaction.set(interviewLinkDocRef, savedProject);
    } catch (err) {
      console.log("Unable to create project!: " + err);
    }
  });

  return savedProject;
}

export async function UpdateResearchProject(savedProject, status, productOverview, interviewGoal, budget) {
  const interviewLinkDocRef = doc(db, "interviewlinks", savedProject.projectDetails.interviewLinkId);
  const projectDocRef = doc(db, "customers", auth.currentUser.uid, "projects", savedProject.projectId);

  var updatedData = {
    "projectDetails.projectName": savedProject.projectDetails.projectName,
    "projectDetails.interviewGoal": interviewGoal,
    "projectDetails.productOverview": productOverview,
    "projectDetails.budget": budget,
  };

  if ((status === "active") && (budget !== 0) && (budget <= savedProject.userInterviewCount)) {
    updatedData = {
      ...updatedData,
      "projectDetails.status": "paused",
    }
  }

  await runTransaction(db, async (transaction) => {
    try {
      transaction.update(projectDocRef, updatedData);
      transaction.update(interviewLinkDocRef, updatedData);
    } catch (err) {
      throw Error("Unable to update project!");
    }
  });

  return await GetResearchProject(savedProject.projectId);
}

export async function UpdateResearchProjectTitle(pid, ilid, projectName) {
  const interviewLinkDocRef = doc(db, "interviewlinks", ilid);
  const projectDocRef = doc(db, "customers", auth.currentUser.uid, "projects", pid);
  const updatedData = {
    "projectDetails.projectName": projectName,
  };

  return await runTransaction(db, async (transaction) => {
    try {
      transaction.update(projectDocRef, updatedData);
      transaction.update(interviewLinkDocRef, updatedData);
    } catch (err) {
      console.log("Unable to update project!");
    }
  });
}

export async function StoreResearchProject(title, product, interview) {
  const hasActiveSubscription = await getSubscriptionState();

  var interviewState = "paused";
  if (hasActiveSubscription) {
    interviewState = "active";
  }

  var interviewLinkDocRef = doc(collection(db, "interviewlinks/"));
  var projectDocRef = doc(collection(db, "customers/" + auth.currentUser.uid + "/projects"));

  let details = {
    accountId: auth.currentUser.uid,
    projectId: projectDocRef.id,
    projectDetails: {
      projectName: title,
      productOverview: product,
      researchGoal: interview,
      status: interviewState,
      interviewLinkId: interviewLinkDocRef.id,
      timestamp: Date.now(),
    }
  }

  return await runTransaction(db, async (transaction) => {
    try {
      transaction.set(projectDocRef, details);
      transaction.set(interviewLinkDocRef, details);
    } catch (err) {
      console.log("Unable to create project!: " + err);
    }
  });
}

export async function DeleteResearchProject(projectId, interviewId) {
  const projectDocRef = doc(db, "customers", auth.currentUser.uid, "projects", projectId);
  const interviewLinkDocRef = doc(db, "interviewlinks", interviewId);

  return await runTransaction(db, async (transaction) => {
    try {
      transaction.delete(projectDocRef);
      transaction.delete(interviewLinkDocRef);
    } catch (err) {
      console.log("Unable to delete project!");
    }
  });
}

/** Stripe Functionality **/

export async function AddMeteredBilling(funcToRun, type) {
  var collectionRef = collection(db, "customers/" + auth.currentUser.uid + "/checkout_sessions");
  var price = (type === "lite") ? 'price_1OgE4TF6BzR0QZVXduvFsIv8' : 'price_1OgE62F6BzR0QZVXuwKN06jq';
  const param = {
    line_items: [
      {
        price: price,
      },
    ],
    success_url: window.location.href,
    cancel_url: window.location.href,
  };
  return await addDoc(collectionRef, param).then(docRef => {
    return onSnapshot(docRef, docSnap => {
      const { sessionId, error } = docSnap.data();
      if (error) {
        throw Error("Failed to create checkout: " + error.message)
      }
      if (sessionId) {
        return funcToRun(sessionId);
      }
    });
  });
}

export async function GetActiveSubscriptionDetails() {
  return GetActiveSubscriptionDetailsForAccount(auth.currentUser.uid);
}

export async function GetActiveSubscriptionDetailsForAccount(aid) {
  var collectionRef = collection(db, "customers/" + aid + "/subscriptions");
  const querySnapshot = await getDocs(collectionRef);
  var details = {};
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      let subscription = doc.data();
      if (subscription && subscription.status === "active") {
        details = subscription;
        return;
      }
    }
  });
  return details;
}

/** Interview Access/Storage */

/** Creates a doc reference to create/update the interview */
export function CreateInterviewTranscriptID(aid, pid) {
  var collectionRef = collection(db, "customers/" + aid + "/projects/" + pid + "/userInterviews");
  var docRef = doc(collectionRef);
  return docRef.id;
}
/** Saves the demo transcript */
export async function SaveDemoTranscript(uid, conversation) {
  var docRef = doc(db, "demodata/" + uid);
  return await setDoc(docRef, {
    transcript: conversation,
    timestamp: Date.now(),
  });
}

export function CreateDemoTranscriptID() {
  var collectionRef = collection(db, "demodata");
  var docRef = doc(collectionRef);
  return docRef.id;
}

/** Saves the interview transcript */
export async function SaveInterviewTranscript(iid, aid, pid, conversation, name, email) {
  var docRef = doc(db, "customers/" + aid + "/projects/" + pid + "/userInterviews/" + iid);
  return await setDoc(docRef, {
    transcript: conversation,
    name: name,
    email: email,
    timestamp: Date.now(),
  });
}

/** Used at the end of the interview to store the full transcript and the summary. */
export async function SaveInterviewTranscriptAndSummary(iid, aid, pid, conversation, summary, name, email) {
  var docRef = doc(db, "customers/" + aid + "/projects/" + pid + "/userInterviews/" + iid);
  return await setDoc(docRef, {
    transcript: conversation,
    name: name,
    email: email,
    summary: summary,
    timestamp: Date.now(),
  });
}

/** Used to update the interview result with the summary. */
export async function SaveInterviewSummary(iid, pid, summary) {
  var docRef = doc(db, "customers/" + auth.currentUser.uid + "/projects/" + pid + "/userInterviews/" + iid);
  return await updateDoc(docRef, {
    summary: summary,
  });
}

export async function GetInterviewDetails(ilid) {
  const docRef = doc(db, "interviewlinks", ilid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function GetInterviews(pid) {
  var interviews = [];
  var collectionRef = collection(db, "customers/" + auth.currentUser.uid + "/projects/" + pid + "/userInterviews");
  const q = query(collectionRef, orderBy("timestamp", "desc"));
  const querySnapshot = await getDocs(q);
  var i = querySnapshot.size;
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    interviews.push({
      interviewId: doc.id,
      timestamp: doc.data().timestamp,
      index: i,
      name: doc.data().name,
      email: doc.data().email,
    });
    i--;
  });

  return interviews;
}

export async function GetInterviewTranscript(pid, iid) {
  var docRef = doc(db, "customers/" + auth.currentUser.uid + "/projects/" + pid + "/userInterviews/" + iid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function ActivateInterview(pid, ilid) {
  const project = await GetResearchProject(pid);
  const hasActiveSubscription = await getSubscriptionState();
  if (hasActiveSubscription) {
    if ((project.projectDetails.budget === 0) || (project.userInterviewCount === undefined) || (project.projectDetails.budget > project.userInterviewCount)) {
      return toggleInterview(pid, ilid, "active");
    } else {
      throw Error("You have exceeded your budget, please update your budget in interview configuration to be able to activate the project.");
    }
  } else {
    throw Error("A payment method needs to be added to your account before activating an interview.");
  }
}

export async function PauseInterview(pid, ilid) {
  return toggleInterview(pid, ilid, "paused");
}

async function toggleInterview(pid, ilid, status) {
  const interviewLinkRef = doc(db, "interviewlinks/", ilid);
  const projectRef = doc(db, "customers/" + auth.currentUser.uid + "/projects/" + pid);
  const updatedStatus = {
    "projectDetails.status": status
  };
  return await runTransaction(db, async (transaction) => {
    try {
      transaction.update(interviewLinkRef, updatedStatus);
      transaction.update(projectRef, updatedStatus);
    } catch (err) {
      console.log("Unable to update status of interview!");
    }
  });
} 
