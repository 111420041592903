import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../components/firebase/firebase';
import { RetryWithExponentialBackoff } from './common';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const functionRef = getFirebaseFunctions();

export const Checkout = async (sessionId) => {
    const stripe = await stripePromise;
    const { checkoutResult } = await stripe.redirectToCheckout({
        sessionId: sessionId,
    });
    if (checkoutResult.error) {
        throw Error("Checkout failed.");
    }
};

export const OpenCustomerPortal = async () => {
    const redirectToPortal = httpsCallable(functionRef, 'ext-firestore-stripe-payments-createPortalLink');
    const { data } = await redirectToPortal({
        returnUrl: window.location.href,
    });
    window.location.assign(data.url);
};

export const ReportUsageWithRetry = async (aid) => {
    return await RetryWithExponentialBackoff(ReportUsage, { aid });
  };

const ReportUsage = async ({aid}) => {
    const createUsageRecord = httpsCallable(functionRef, 'createUsageRecord');
    try {
        await createUsageRecord({
            aid: aid,
        });
    } catch (error) {
        throw Error(`Usage report failed for ${aid}:` + error.toString());
    }
};
