// Table.js
import React from 'react';
import { useTable } from 'react-table';

import "./Table.css"

function getCellStyle(value) {
  let color;
  switch (value) {
    case "Active":
      color = "#27A376";
      break;
    case "Inactive":
      color = "#888888";
      break;
    case "Draft":
      color = "#8495AE";
      break;
    default:
      color = "inherit";
  }

  return { color };
}


const Table = ({ columns, data, rowSelectFn }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()} className="projects-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps()}
                className="table-header"
                style={{
                  borderBottom: '1px solid #CCDDD7',
                  textAlign: index === headerGroup.headers.length - 1 ? 'right' : 'left',                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}
                className="table-row"
                onClick={() => {
                  if (rowSelectFn != null) {
                    rowSelectFn(row.original);
                  }
                }}
                style={{ borderBottom: '1px solid #CCDDD7' }}>
              {row.cells.map((cell, index) => {
                const cellStyle = index === 1 ? getCellStyle(cell.value) : {};
                return <td {...cell.getCellProps()}
                  className="table-cell"
                  style={{
                    ...cellStyle,
                    textAlign: index === row.cells.length - 1 ? 'right' : 'left',
                  }}
                  >
                  {cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
