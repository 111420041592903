import TestInterview from './pages/TestInterview';
import UserInterview from './pages/UserInterview';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import HomeLayout from './components/home/HomeLayout';

import Account from './pages/Account';
import LandingPage from './pages/LandingPage';
import InterviewResult from './pages/InterviewResult';
import ResearchProject from './pages/ResearchProject';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route element={<HomeLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="account" element={<Account />} />
          <Route path="account/settings" element={<Account />} />
          <Route path="account/usage" element={<Account />} />
          <Route path="account/billing" element={<Account />} />
          <Route path="project">
            <Route path=":id/results/:iid" element={<InterviewResult />} />
            <Route path=":id" element={<ResearchProject />} />
          </Route>
        </Route>
        <Route path="project/:id/userinterview" element={<UserInterview />} />
        <Route path="project/testinterview" element={<TestInterview />} />
        <Route path="privacy_policy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />

      </Routes>
    </BrowserRouter>
  );
}