// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import { getAuth, GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import { GetFirebaseConfig } from '../firebase/firebase';
import './SignInScreen.css'
import './firebaseui-styling.global.css'

// Configure Firebase.
const config = GetFirebaseConfig();
var app = firebase.initializeApp(config);
var auth = getAuth(app);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/home',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Called when the user has been successfully signed in.
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      if (authResult.user) {
        return true;
      }
      // Do not redirect.
      return false;
    }
  },
};
//TODO: pass text to the below function so it changes whether it says sign-in/up depending on the button clicked
function SignInScreen() {
  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
  );
}

export default SignInScreen