import './InputFields.css';
import * as React from 'react';

export function Budget({ defaultValue, budget, existingUserInterviewCount }) {
    const setValue = (e) => {
        const value = (isNaN(e.target.valueAsNumber) || e.target.value === "") ? 0 : e.target.valueAsNumber;
        budget(value);
    };
    return (
        <div style={{ marginTop: "3rem" }}>
            <h5>Interview limit</h5>
            <p style={{ lineHeight: "150%" }}>
                Set the maximum number of interviews you want to conduct for this project. Once this number is reached, users will no longer be able to provide feedback through the interview link. If you do not want to set a limit, leave this field blank. Completed interviews will be charged at $5 each.
            </p>
            {existingUserInterviewCount > 0 &&
                <p style={{ lineHeight: "150%"}}> {existingUserInterviewCount} interview{existingUserInterviewCount > 1 && <span>s have</span>}{existingUserInterviewCount === 1 && <span> has</span>} been conducted in this project so far.</p>}
            <div style={{ maxWidth: "150px" }}>
                <input
                    className="textbox"
                    type="number"
                    value={(isNaN(defaultValue) || defaultValue === 0) ? "" : defaultValue}
                    onWheel={(e) => e.target.blur()}
                    placeholder='e.g., 150'
                    onChange={(e) => setValue(e)}
                />
            </div>
            <p style={{ color: "#888" }}>Note: If the limit is updated while the project is active, any interviews that are being conducted will still be allowed to finish even if they exceed the limit.</p>
        </div>);
}

export function InterviewGoal({ defaultValue, interviewGoal }) {
    return (<div>
        <h5>Interview goal</h5>
        <p className="description-text">
            Describe the types of insights that you hope to gain from this interview. We recommend providing broad objectives rather than overly specific instructions.
        </p>
        <div className="text-input">
            <textarea
                rows="5"
                className="textbox"
                maxLength={1000}
                defaultValue={defaultValue}
                placeholder='E.g., Understand how our users are using our product, what features they like, what frustrations they have, and whether they have any suggestions for us to improve it. We’ll use this feedback to inform decisions on prioritizing...'
                onChange={(e) => {
                    interviewGoal(e.target.value);
                }}
            />
        </div>
    </div>);
}

export function ProductOverview({ defaultValue, productOverview }) {
    return (<div>
        <h5>Product/Feature Overview</h5>
        <p className="description-text">
            Provide a description of the product you want the chatbot to discuss with users. The more details you give, the more in-depth questions the chatbot can generate.
        </p>
        <div className="text-input">
            <textarea
                rows="5"
                className="textbox"
                maxLength={1000}
                placeholder='E.g., Pansophic, a web platform that allows users to configure AI user interview agents which can autonomously conduct user interviews and summarize the feedback provided. Users can train interview agents, set budgets...'
                defaultValue={defaultValue}
                onChange={(e) => {
                    productOverview(e.target.value);
                }}
            />
        </div>
    </div>);
}