import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import { chatCompletionWithRetry, createInterviewSummaryWithRetry } from '../api/ChatCompletion';
import Logo from "../assets/logo.svg";
import Send from "../assets/send.svg";

import './ChatInterview.css';
import { GetInterviewDetails, SaveInterviewTranscript, SaveInterviewTranscriptAndSummary, CreateInterviewTranscriptID } from '../api/Firestore';
import { PermanentDialogue, ActionDialogueAlternate } from '../components/common/Dialogue';
import { NameAndEmailDialog } from '../components/interview/NameEmailCollectionDialogue';
import { ReportUsageWithRetry } from '../api/Stripe';
import { useBeforeunload } from 'react-beforeunload';
import LoadingScreen from '../components/common/LoadingScreen';


const MIN_USER_INPUT_FOR_CHARGE = 3;

function UserInterview() {
    const dataFetchedRef = useRef(false);
    const [userInput, setUserInput] = useState([]);
    const [project, setProject] = useState();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [allowUserInput, setAllowUserInput] = useState(false);
    const [showEndDialogue, setShowEndDialogue] = useState(false);
    const [showEndConfirmationDialog, setshowEndConfirmationDialog] = useState(false);
    const [showPausedInterviewDialogue, setShowPausedInterviewDialogue] = useState(false);
    const [showNameAndEmailDialog, setShowNameAndEmailDialog] = useState(true);
    const [conversation, setConversation] = useState([]);
    const [isCustomerCharged, setIsCustomerCharged] = useState(false);
    const [interviewId, setInterviewId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const generateAIResponse = (pid, pd, conversation) => {
        chatCompletionWithRetry(pid, pd.productOverview, pd.interviewGoal, conversation).then(r => {
            setConversation((prevMessages) => {
                setAllowUserInput(true);
                return [...prevMessages, { "role": "assistant", "content": r }];
            });
        }).catch(err => {
            // TODO(pozlen): Show a toast to indicate the retries failed.
            console.log("Completion did not work: " + err);
            setAllowUserInput(true);
        });
    };

    const handleEndDialogOpen = () => {
        setshowEndConfirmationDialog(true);
    };

    const handleEndDialogCancel = () => {
        setshowEndConfirmationDialog(false);
    };

    const handleEndDialogProceed = () => {
        setshowEndConfirmationDialog(false);
        setIsLoading(true);
        endInterview();
    };

    const handleNameAndEmailDialogClose = () => {
        setShowNameAndEmailDialog(false);
    };

    const handleNameAndEmailDialogConfirm = (name, email) => {
        if (name && email) {
            setName(name);
            setEmail(email);
            setShowNameAndEmailDialog(false);
        }
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        var ilid = getInterviewLinkId();
        GetInterviewDetails(ilid).then((project) => {
            setProject(project);
            var pd = project.projectDetails;
            if (pd.status !== "active") {
                setShowPausedInterviewDialogue(true);
                setShowNameAndEmailDialog(false);
                setAllowUserInput(false);
                return;
            }
            generateAIResponse(project.projectId, pd, []);
            const interviewId = CreateInterviewTranscriptID(project.accountId, project.projectId);
            setInterviewId(interviewId);
            dataFetchedRef.current = true;
        });
    }, []);

    useBeforeunload((event) => {
        if (showEndDialogue) {
            // if the interview already ended, just let them go.
            return;
        }
        event.preventDefault();
    });

    const getInterviewLinkId = () => {
        var arr = window.location.pathname.split('/');
        return arr[arr.length - 2];
    }

    const submitInput = () => {
        if (!isValidUserInput(userInput)) {
            return;
        }

        if (allowUserInput) {
            setAllowUserInput(false);
            let input = sanitiseInput(userInput);
            setConversation((prevMessages) => {
                return [
                    ...prevMessages,
                    { "role": "user", "content": input },
                ]
            });
            let updatedConvo = [
                ...conversation,
                { "role": "user", "content": input },
            ];
            generateAIResponse(project.projectId, project.projectDetails, updatedConvo);
            maybeChargeAndStoreInterview(updatedConvo);
            setUserInput("");
        }
    }

    const maybeChargeAndStoreInterview = async (c) => {
        if (isValidInterview(c)) {
            try {
                if (!isCustomerCharged) {
                    ReportUsageWithRetry(project.accountId);
                    setIsCustomerCharged(true);
                }
                SaveInterviewTranscript(interviewId, project.accountId, project.projectId, c, name, email);
            } catch (err) {
                console.log("Error reporting and/or saving transcipt: " + err);
            }
        }
    }

    const endInterview = async () => {
        if (isValidInterview(conversation)) {
            var summary = await createInterviewSummaryWithRetry(project.projectId, conversation);
            SaveInterviewTranscriptAndSummary(interviewId, project.accountId, project.projectId, conversation, summary, name, email).then(() => {
                setIsLoading(false);
                setShowEndDialogue(true);
                setUserInput(false);
            });
        } else {
            setShowEndDialogue(true);
            setUserInput(false);
        }
    }

    const isValidInterview = (c) => {
        // Allow for AI input along with system prompt.
        const userInputTyped = Math.floor( c.length / 2);
        return userInputTyped >= MIN_USER_INPUT_FOR_CHARGE;
    }
    
    const isValidUserInput = (input) => {
        if (input.toString().trim().length === 0) return false;
        if (input === '\n') return false;
        return true;
    }

    const sanitiseInput = (input) => {
        return input.replace(/^\s+|\s+$/g, '');
    }
    
    return (
        <div className='interview-body'>
            <LoadingScreen isLoading={isLoading} />
            <div className='interview-header'>
                <img className="interview-logo" src={Logo} alt="Logo" />
            </div>
            <div className='interview-container'>
                <div>
                    <List className='interview-chat-container'>
                        {conversation.map((m, index) => (
                            <li key={index} className={m.role === "user" ? "right-align" : ""}>
                                <div className='sender-title'>{m.role === "user" ? "You" : "Interviewer"}</div>
                                <div className={m.role === "user" ? "user-bubble" : "chatbot-bubble"}>
                                    <span>{m.content}</span>
                                </div>
                            </li>
                        ))}
                        {!allowUserInput && <div>
                            <div className='sender-title'>Interviewer</div>
                            <div className="chatbot-bubble">
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>}
                        <div className='interview-chat-top-overlay' />
                        <div className='interview-chat-bottom-overlay' />
                    </List>
                </div>
                <NameAndEmailDialog open={showNameAndEmailDialog}
                    title="Welcome!"
                    content="If you'd like your name and email to be included with your interview, please fill them out below. Otherwise, you can skip this step and get started."
                    confirm={handleNameAndEmailDialogConfirm}
                    cancel={handleNameAndEmailDialogClose}
                    email= {true}
                    name= {true}
                    />
                <PermanentDialogue open={showEndDialogue}
                    title="Interview complete"
                    content="Thank you for taking the time to provide your feedback! Feel free to close the tab." />
                <PermanentDialogue open={showPausedInterviewDialogue}
                    title="We're currently not accepting additional feedback."
                    content="Thank you for taking the time to provide your feedback!" />
                <ActionDialogueAlternate open={showEndConfirmationDialog}
                    title="End interview?"
                    content="Please confirm if you'd like to end the interview."
                    cancel={handleEndDialogCancel}
                    confirm={handleEndDialogProceed}
                    cancelText="Cancel"
                    confirmText="Confirm"
                />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <form style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <textarea
                            className='interview-input-box'
                            placeholder="Send a message..."
                            aria-label="new user input"
                            value={userInput}
                            rows={3}
                            onChange={(e) => {
                                if (e.target.value !== '\n' || e.target.value !== '') {
                                    setUserInput(e.target.value)
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submitInput();
                                }
                            }}
                        />
                        <input
                            type="image"
                            src={Send}
                            disabled={!allowUserInput || isValidUserInput(userInput) === false}
                            className='send-button'
                            aria-label="send message"
                            onClick={() => {
                                submitInput();
                            }}
                        />
                    </form>
                    <button className="text-button" style={{ marginTop: '2rem', filter: "saturate(0)" }} onClick={handleEndDialogOpen}>End interview</button>
                </div>
            </div>
        </div>
    );
}



export default UserInterview;
