import { Outlet } from 'react-router-dom';
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import "./HomeLayout.css";
import Logo from "../../assets/logo_white.svg"
import { useState, useEffect } from 'react';
import { getFirebaseAuth } from '../../components/firebase/firebase';
import hamburger from "../../assets/hamburger.svg";
import User from "../../assets/user.svg";
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { getSubscriptionState } from '../stripe/subscriptions';
import LoadingScreen from '../common/LoadingScreen';
import PaymentBanner from './PaymentBanner';


export default function HomeLayout() {
    const auth = getFirebaseAuth();

    const navigate = useNavigate();

    const [isPaidCustomer, setIsPaidCustomer] = useState(true);
    const [header, setHeader] = useState("Home");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate('/');
            } else {
                getSubscriptionState().then((res)=>{
                    setIsPaidCustomer(res);
                    setIsLoading(false);
                })
            }
        });
    }, [auth, navigate]);

    const signOut = () => {
        auth.signOut();
    }

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const sidebar = (
        <div className={`sidebar${sidebarOpen ? " open" : ""}`}>

            <img className="logo" src={Logo} alt="Logo" style={{cursor: "pointer"}} onClick={() => navigate('/home')}/>

            <a href="/home">
                <HomeOutlinedIcon className="sidebar-icon" />
                Home
            </a>
            <a href="/account">
                <AccountCircleOutlinedIcon className="sidebar-icon" />
                Account
            </a>
        </div>
    );

    const headerBar = (
        <header className="header-bar">
            {/*TODO: Fix responsiveness of hamburger button now that sidebar overlaps header*/}
            <img
                className="hamburger"
                onClick={toggleSidebar}
                src={hamburger}
                alt="Hamburger Menu"
            />
            <div className='page-name'>
                {header}
            </div>
            <img 
                className="user-icon"
                src={User} alt="User Icon"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                role='menuitem'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={signOut}>Sign out</MenuItem>
            </Menu>
        </header>
    );

    const loggedinView = (<div>
        <PaymentBanner isFreemium={!isPaidCustomer}/>
        {sidebar}
        <div>
            <div className="main-content">
                {headerBar}
                <div className='sub-header-bg' />
                <Outlet context={[isPaidCustomer, setHeader]} />
            </div>
        </div>
    </div>);

    const loadingView = (<div>
        <LoadingScreen isLoading={isLoading}></LoadingScreen>
    </div>);

    return isLoading ? loadingView : loggedinView;
}

