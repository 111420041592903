import { useLocation, useOutletContext } from "react-router-dom";
import { useState, useEffect } from 'react';
import { GetResearchProject, SaveResearchProject, UpdateResearchProjectTitle } from "../api/Firestore";
import LoadingScreen from "../components/common/LoadingScreen";
import ProjectConfig from "../components/researchproject/ProjectConfig";
import ProjectResults from "../components/researchproject/ProjectResults";
import InterviewActivationButton from "../components/researchproject/InterviewActivationButton";

function ResearchProject() {
    const { state } = useLocation();
    const projectId = state.projectId;

    // eslint-disable-next-line no-unused-vars
    const [isPaidCustomer, setHeader] = useOutletContext();
    const [status, setStatus] = useState('');
    const [activeSection, setActiveSection] = useState('results');
    const [savedProject, setSavedProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("");
    

    useEffect(() => {
        // Check if the project is valid.
        GetResearchProject(projectId).then(project => {
            if (project !== null) {
                setSavedProject(project);
                setStatus(project.projectDetails.status);
                setTitle(project.projectDetails.projectName);
                if (project.projectDetails.status === "draft") {
                    setActiveSection("config");
                }
            } else {
                setStatus("draft");
                setActiveSection("config");
            }
            setIsLoading(false);
        });
    }, [projectId]);

    useEffect(() => {
        if (title === "") {
            setHeader("Unnamed Project");
        } else {
            setHeader(title);
        }
    }, [setHeader, title]);

    useEffect(() => {
        if (savedProject !== null) {
            setStatus(savedProject.projectDetails.status);
        }
    }, [savedProject]);

    const switchSection = (section) => {
        setActiveSection(section);
    };

    const getStatusColor = () => {
        if (status === "draft") {
            return "#8DB4D8";
        } else if (status === "active") {
            return "#5FA598";
        } else if (status === "paused") {
            return "#AAA";
        }
    }

    const saveTitle = async () => {
        if (title === "") {
            return;
        }
        try {
            if (savedProject === null) {
                const updatedProject = await SaveResearchProject(projectId,
                    title,
                    "",
                    "",
                    0);
                setSavedProject(updatedProject);
            } else {
                await UpdateResearchProjectTitle(projectId, savedProject.projectDetails.interviewLinkId, title);
                setSavedProject({
                    ...savedProject,
                    projectDetails: {
                        ...savedProject.projectDetails,
                        projectName: title,
                    }
                });
            }
        } catch (err) {
            console.log("Error updating title: " + err);
        }
    }

    return (
        <div>
            <LoadingScreen isLoading={isLoading} />
            <div className="container">
                <div>
                    <div className="title" style={{ marginBottom: "0" }}>
                        <input
                            autoFocus
                            type="text"
                            maxLength="40"
                            placeholder="Unnamed Project"
                            className="project-title"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            onBlur={saveTitle}
                        />
                        <InterviewActivationButton project={savedProject}
                            status={status}
                            setStatus={setStatus}
                            updateSavedProject={setSavedProject}
                            navigateToResults={() => setActiveSection("results")}/>
                    </div>
                    <div className="project-status" style={{
                        color: getStatusColor(),
                        textTransform: 'uppercase',
                    }}
                    >{status}</div>

                </div>
            </div>

            <div className="container">
                <div className="project-tab-links">
                    <span className={`link ${activeSection === 'config' ? 'active' : ''}`}
                        onClick={() => switchSection('config')}>
                        Interview Configuration
                    </span>
                    <span
                        className={`link ${activeSection === 'results' ? 'active' : ''}`}
                        onClick={() => switchSection('results')}>
                        Results
                    </span>
                </div>
                <hr style={{marginBottom: "1.5rem"}} />

            </div>


            <div className={(activeSection === 'config') ? 'visible' : 'hidden'}>
                <ProjectConfig projectId={projectId} status={status} projectName={title} savedProject={savedProject} updateSavedProject={setSavedProject} />
            </div>
            <div className={(activeSection === 'results') ? 'visible' : 'hidden'}>
                <ProjectResults projectId={projectId} savedProject={savedProject}/>
            </div>
        </div>
    );
}
export default ResearchProject;