import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useState } from 'react';


function PasswordLogin({ open, onClose, onSubmit }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleOnSubmit = () => {
        onSubmit(email,password);
    }

    return (
        <Dialog
            onClose={onClose}
            open={open}>
            <DialogTitle>Confirm credentials</DialogTitle>
            <TextField required id="filled-basic" label="Email" variant="filled" onChange={e => setEmail(e.target.value)} />
            <TextField required id="filled-basic" label="Password" type="password"
                variant="filled" onChange={e => setPassword(e.target.value)} />
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleOnSubmit} style={{ color: '#D15252' }} >Delete Account</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PasswordLogin;