import React from 'react';
import { useTable } from 'react-table';

import "./Table.css"

const Table = ({ columns, data, rowSelectFn }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()} className="interviews-projects-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps()}
                className="interviews-table-header"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}
                className="interviews-table-row"
                onClick={() => {
                  if (rowSelectFn != null) {
                    rowSelectFn(row.original);
                  }
                }}
                style={{ borderBottom: '1px solid #CCDDD7' }}>
              {row.cells.map((cell, index) => {
                return <td {...cell.getCellProps()}
                  className="interviews-table-cell"
                  >
                  {cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
