import React from 'react';
import { useState, useEffect } from "react";
import Table from '../components/home/Table';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useOutletContext } from "react-router-dom";
import { CreateResearchProjectId, ListResearchProjects } from '../api/Firestore';
import Owl from "../assets/bg_owl.svg";


import "./Home.css";
import './ChatInterview.css';

function Home() {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [isPaidCustomer, setHeader] = useOutletContext();

  const [projectLoadState, setProjectLoadState] = useState("loading");
  const [researchProjects, setResearchProjects] = useState([]);

  useEffect(() => {
    setHeader("Home");
    ListResearchProjects().then((projects) => {
      setResearchProjects(projects);
      setProjectLoadState("loaded");
    }).catch((err) => {
      setProjectLoadState("error");
      console.log("Error retrieving projects from DB: " + err);
      throw err;
    });
  }, [setHeader]);

  const columns = React.useMemo(
    () => [
      { Header: 'Project name', accessor: 'projectDetails.projectName' },
      { Header: 'Status', accessor: 'projectDetails.status' },
      { Header: '# of responses',
        accessor: 'userInterviewCount',
        Cell: ({ value }) => {
          return <div>{!value ? 0 : value}</div>;
        },
      },
      {
        Header: 'Date created',
        accessor: 'projectDetails.timestamp',
        Cell: ({ value }) => {
          const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(value);
          return <div>{formattedDate}</div>;
        },
      },
    ],
    []
  );

  const navigateToProjectCreate = () => {
    const projectId = CreateResearchProjectId();
    navigateToProject(projectId);
  }

  const clickedOnAProject = (project) => {
    navigateToProject(project.projectId);
  }

  const navigateToProject = (pid) => {
    navigate('/project/' + pid, {
      state: {
        projectId: pid
      },
    });
  }

  return (
    <div>
      <div className="container">
        <div className="title">
          <h1>Research Projects</h1>
          <button className="custom-button" style={{ paddingLeft: '10px' }} onClick={() => navigateToProjectCreate()}>
            <AddIcon style={{ color: 'white', margin: '0px', marginRight: '2px' }} />
            New Project
          </button>
        </div>

        <div hidden={projectLoadState !== "error"}>
          Error loading projects, we're sorry. Please try refreshing the page.
        </div>
        <div hidden={researchProjects.length === 0}>
          <Table columns={columns} data={researchProjects} rowSelectFn={clickedOnAProject} />
        </div>
        {/** TODO(pozlen): Update with the new design. */}
        <div hidden={projectLoadState === "loading" || projectLoadState === "error" ||
          (projectLoadState === "loaded" && researchProjects.length !== 0)}>
            <div className="home-empty-state">
              <div className="home-empty-state-left">
              You don't have any research projects yet. Projects you create will appear here.
              <div>
              <button className="custom-button" style={{backgroundColor: "#FFF", color: "#5FA598", marginTop: "2rem"}} onClick={() => navigateToProjectCreate()}>
              Create your first project
                </button>
                </div>

              </div>
              <div className="home-empty-state-right">
                <img src={Owl} alt="Owl"/>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
