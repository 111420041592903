import React from 'react';
import { useState, useEffect } from "react";
import Table from '../components/account/table';

import "./Home.css";
import './Account.css';
import { AddMeteredBilling, GetActiveSubscriptionDetails, GetInterviews, ListResearchProjects } from '../api/Firestore';
import { Checkout, OpenCustomerPortal } from '../api/Stripe';
import { EmailAuthProvider, GoogleAuthProvider, getAuth, reauthenticateWithCredential, reauthenticateWithPopup } from 'firebase/auth';
import { ActionDialogueAlternate } from '../components/common/Dialogue';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordLogin from '../components/auth/PasswordLogin';

function Account() {

  // eslint-disable-next-line no-unused-vars
  const [isPaidCustomer, setHeader] = useOutletContext();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [interveiws, setInterviews] = useState([]);
  const [researchProjects, setResearchProjects] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [showAccountDeletionDialogue, setShowAccountDeletionDialogue] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);
  const [activeSection, setActiveSection] = React.useState("section1");

  const currentUser = getAuth().currentUser;
  const currentPath = useLocation().pathname;

  useEffect(() => {
    ListResearchProjects().then(r => {
      setResearchProjects(r);
    });
    GetActiveSubscriptionDetails().then(r => {
      setSubscriptionDetails(r);
      setIsLoading(false);
    });
    setHeader("Account");
    setName(currentUser.displayName);
    setEmail(currentUser.email);
  }, [currentUser, setHeader]);

  useEffect(() => {
    const pathSuffix = currentPath.split("/account").pop();
    const pathsToSections = {
      "/": "section1",
      "/settings": "section1",
      "/usage": "section2",
      "/billing": "section3"
    };
    const section = pathsToSections.hasOwnProperty(pathSuffix) ? pathsToSections[pathSuffix] : "section1";
    setActiveSection(section);
  }, [currentPath]);

  useEffect(() => {
    researchProjects.forEach(
      r => {
        GetInterviews(r.projectId).then(
          i => {
            setInterviews((prev) => {
              return [...prev, i];
            })
          });
      })
  }, [researchProjects]);

  // Account Section
  const clickedDeleteAccount = () => {
    setShowAccountDeletionDialogue(true);
  };

  const handleAccountDeletionCancel = () => {
    setShowAccountDeletionDialogue(false);
  }

  const handleAccountDeletionConfirm = async () => {
    var provider = currentUser.providerData[0].providerId;
    try {
      if (provider === "google.com") {
        // Reauthenticate and delete user data.
        const provider = new GoogleAuthProvider();
        const credentials = await reauthenticateWithPopup(currentUser, provider);
        if (credentials) {
          await credentials.user.delete();
        }
      } else if (provider === "password") {
        setShowPasswordLogin(true);
      } else {
        throw Error("Unsupported authentication method used...");
      }
    } catch (e) {
      console.log("Something went wrong trying to delete the account...");
    }
  }

  const handlePasswordLogin = async (email, password) => {
    try {
      const authCredential = EmailAuthProvider.credential(email, password);
      const userCredential = await reauthenticateWithCredential(currentUser, authCredential);
      if (userCredential) {
        await userCredential.user.delete();
      }
    } catch (e) {
      if (e.code === "auth/wrong-password") {
        // TODO: Show a toast for invalid login
        setShowPasswordLogin(false);
        setShowAccountDeletionDialogue(false);
      } else {
        console.log("Something went wrong trying to delete the account...: " + e);
      }
    }
  }

  // Usage Section
  const columns = [
    {
      Header: 'Project',
      accessor: 'project',
    },
    {
      Header: '# of Interviews',
      accessor: 'numInterviews',
    },
  ];

  const getData = () => {
    var usage = [];
    researchProjects.map(r => {
      if (!r.userInterviewCount || r.userInterviewCount === 0) {
        return usage;
      }
      return usage.push({
        project: r.projectDetails.projectName,
        numInterviews: r.userInterviewCount,
        // cost: r.userInterviewCount * interviewCost,
      });
    });
    return usage;
  };

  const checkoutLite = async () => { checkout("lite") };
  const checkoutPro = async () => { checkout("pro") };

  // Billing Section
  const checkout = async (subscriptionType) => {
    setIsLoading(true);
    try {
      await AddMeteredBilling(stripeCheckout, subscriptionType);
      // There is a potential issue here where the function returns earlier than
      // redirect so can't set the loading state to false. Leaving as is since the
      // user interaction seems to workout ok.
      // setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const stripeCheckout = async (sessionId) => {
    await Checkout(sessionId);
  }

  const handleStripeSend = () => {
    setIsLoading(true);
    try {
      OpenCustomerPortal();
    } catch (err) {
      console.log(err);
      // Show an error toast
      setIsLoading(false);
    }
  }

  const getSubscriptionPlan = () => {
    if (subscriptionDetails.items) {
      return subscriptionDetails.items[0].price.id === "price_1OgE4TF6BzR0QZVXduvFsIv8" ? "lite" : "pro";
    }
  }

  const getInterviewsInBillingCycle = () => {
    if (interveiws) {
      let startDate = getBillingCycleStartDateTs();
      let endDate = getBillingCycleEndDateTs();
      let count = 0;
      interveiws.forEach(i => 
        i.forEach(ii => {
          let d = new Date(ii.timestamp);
          if(d <= endDate && d >= startDate) {
            count++;
          }})
      );
      return count;
    }
  }

  const getBillingCycleStartDateTs = () => {
    if (subscriptionDetails.current_period_start) {
      return new Date(subscriptionDetails.current_period_start.seconds * 1000);
    }
  }

  const getBillingCycleEndDateTs = () => {
    if (subscriptionDetails.current_period_end) {
      return new Date(subscriptionDetails.current_period_end.seconds * 1000);
    }
  }

  const getNextBillingCycle = () => {
    if (subscriptionDetails.current_period_end) {
      var date = getBillingCycleEndDateTs();
      const month = date.getUTCMonth() + 1; // months from 1-12
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();
      const newDate = month + "/" + day + "/" + year;
      return newDate;
    }
  }

  const getInterviewInPlan = () => {
    return getSubscriptionPlan() === "lite" ? "10" : "30"
  }

  return (
    <div>
      <Backdrop
        style={{ backgroundColor: '#2F5952', opacity: 0.6 }}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container">
        <div className="links">
          <Link
            to="/account/settings"
            className={`link ${activeSection === 'section1' ? 'active' : ''}`}
          >
            Settings
          </Link>
          <Link
            to="/account/usage"
            className={`link ${activeSection === 'section2' ? 'active' : ''}`}
          >
            Usage
          </Link>
          <Link
            to="/account/billing"
            className={`link ${activeSection === 'section3' ? 'active' : ''}`}
          >
            Billing
          </Link>

        </div>
        <hr />
        {activeSection === 'section1' &&
          <div>
            <ActionDialogueAlternate open={showAccountDeletionDialogue}
              title="Are you sure you'd like to delete your account?"
              content="Please confirm if you'd like to proceed - keep in mind this action is irreversible and you'll lose all associated data."
              cancel={handleAccountDeletionCancel}
              confirm={handleAccountDeletionConfirm}
              cancelText="Cancel"
              confirmText="Delete Account"
            />
            <PasswordLogin open={showPasswordLogin}
              onSubmit={handlePasswordLogin}
              onClose={() => {
                setShowPasswordLogin(false);
                setShowAccountDeletionDialogue(false)
              }} />
            <div className="setting-fields">

              <div className='setting-fields-columns'>
                <p className="input-title2"> Name </p>
                <p className="input-title2"> Email </p>
              </div>
              <div className='setting-fields-columns'>
                <p>{name}</p>
                <p>{email}</p>
              </div>
            </div>

            <div style={{ marginTop: "2rem" }}>
              Questions? Reach out to us at <a style={{ textDecoration: "none", color: "#00B897" }} className="mailto" href="mailto:contact@pansophic.co">contact@pansophic.co</a>

            </div>
            <div className='account-buttons'>
              <div className='account-text-buttons'>
                <button className="text-button" onClick={clickedDeleteAccount} style={{ color: '#D15252' }}>Delete Account</button>
              </div>
            </div>
          </div>}

        {activeSection === 'section2' &&
          <div>
            <p>You've used {getInterviewsInBillingCycle()} of {getInterviewInPlan()} interviews included in your plan this billing cycle. Your new billing cycle starts on {getNextBillingCycle()}.</p>
            {researchProjects.length > 0 ?
              <Table columns={columns} data={getData()} /> :
              <div> You have no interviews conducted yet.</div>}
          </div>}

        {activeSection === 'section3' &&
          <div>
            {subscriptionDetails && subscriptionDetails.status === "active" && <div>
              <p>You are subscribed to the Pansophic {getSubscriptionPlan()} plan. <span style={{ fontWeight: "700", color: "#2ec790" }}>✓</span></p>
              <p>Your next billing cycle starts on {getNextBillingCycle()}. To switch plans, cancel your current plan and sign up on a different one. </p>
              <button className="custom-button" style={{ marginTop: "2rem" }} onClick={handleStripeSend}>Manage Billing</button>
            </div>
            }

            {(!subscriptionDetails || subscriptionDetails.status !== "active") && <div>
              <div className="billing-pricing-section">
                <p>Choose a plan below to get started.</p>
                <div className="billing-pricing">
                  <div id="Pricing" className="billing-pricing-boxes">
                    <h3>Lite</h3>
                    <p>10 interviews included each month, $5 per interview afterwards.</p>
                    <h2>$25<span style={{ fontSize: "14px" }}>/month</span></h2>
                    <button className="custom-button" style={{ marginTop: "2rem" }} onClick={checkoutLite}>Select Lite</button>

                  </div>

                  <div id="Pricing" className="billing-pricing-boxes">
                    <h3>Pro</h3>
                    <p>30 interviews included each month, $3 per interview after that.</p>
                    <h2>$60<span style={{ fontSize: "14px" }}>/month</span></h2>
                    <button className="custom-button" style={{ marginTop: "2rem" }} onClick={checkoutPro}>Select Pro</button>

                  </div>

                  <div id="Pricing" className="billing-pricing-boxes">
                    <h3>Enterprise</h3>
                    <p>Please reach out to us at <span style={{ fontWeight: "700" }}>contact@pansophic.co</span> if you need more interviews or a different pricing configuration. </p>
                    <h2>Contact us</h2>
                  </div>

                </div>


              </div>
            </div>
            }
          </div>}
      </div>
    </div>
  );
}
export default Account;
