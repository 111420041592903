import { useNavigate, useOutletContext } from "react-router-dom";
import { ActivateInterview, PauseInterview } from "../../api/Firestore";
import { ActionDialogue } from "../common/Dialogue";
import { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { ErrorSnackbar } from "../common/Snackbar";

function InterviewActivationButton({ project, status, setStatus, navigateToResults }) {
    const navigate = useNavigate();

    const [isPaidCustomer] = useOutletContext();
    const [showPaymentAddDialogue, setPaymentAddDialogue] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const isButtonDisabled = () => {
        // We should always be able to toggle an active project back to paused.
        if (status === "active") {
            return false;
        }

        // Check if there's a valid project configuration.
        if (!isProjectConfigurationValid(project)) {
            return true;
        }

        // Check if there's enough budget left   
        if (isBudgetLeft()) {
            return false;
        }

        return true;
    }

    const isBudgetLeft = () => {
        if (project === null) {
            return false;
        } else if (project.projectDetails.budget === 0) {
            // infinite budget
            return true;
        } else if (project.projectDetails.budget <= project.userInterviewCount) {
            return false;
        }
        return true;
    }
    const isProjectConfigurationValid = (project) => {
        if ((project === null) || (project === undefined) || (project.projectDetails === undefined)) {
            return false;
        }
        const pd = project.projectDetails;
        if ((pd.productOverview === "") || (pd.productOverview === undefined)) {
            return false;
        }
        if ((pd.interviewGoal === "") || (pd.interviewGoal === undefined)) {
            return false;
        }
        return true;
    }

    const handlePaymentAddCancel = () => {
        setPaymentAddDialogue(false);
    }

    const handlePaymentAddConfirm = () => {
        setPaymentAddDialogue(false);
        navigate('/account/billing');
    }

    const toggleStatus = async () => {
        try {
            if (status === "active") {
                await PauseInterview(project.projectId, project.projectDetails.interviewLinkId);
                setStatus("paused");
            } else if (isPaidCustomer) {
                await ActivateInterview(project.projectId, project.projectDetails.interviewLinkId);
                setStatus("active");
                navigateToResults();
            } else {
                setPaymentAddDialogue(true);
            }         
        } catch (err) {
            setErrorMessage(err.message)
        }
    };

    const getStatusButtonText = () => {
        if (status !== "active") {
            return "Activate interview";
        }
        return "Pause interview";
    }

    const getStatusButtonColour = () => {
        if (isButtonDisabled()) {
            return "#aaa"
        }
        if (status !== "active") {
            return "#5FA598";
        }
        return "#D15252";
    }

    //function for getting tooltip text
    const getTooltipText = () => {
        if (isButtonDisabled()) {
            return "You need to configure the interview, save changes, and not have exceeded your interview limit (if you have set one) in order to activate the project";
        }
        else if (status === "active") {
            return "If you pause the interview, users will not be able to chat with the interview agent when they navigate to the interview link.";
        }
    }

    return (<div>
        <ActionDialogue open={showPaymentAddDialogue}
            title="Payment method required"
            content="Please add a payment method to your account to activate the interview."
            cancel={handlePaymentAddCancel}
            confirm={handlePaymentAddConfirm}
            cancelText="Cancel"
            confirmText="Add Payment Method"
        />
        <Tooltip title={getTooltipText()}>
            <span>
                <button
                    className="outline-button"
                    onClick={toggleStatus}
                    disabled={isButtonDisabled()}
                    style={{ color: getStatusButtonColour(), borderColor: getStatusButtonColour(), }}>
                    {getStatusButtonText()}
                </button>
            </span>
        </Tooltip>
        <ErrorSnackbar
            open={(errorMessage !== "") ? true : false}
            errMsg={errorMessage}
            onClose={() => setErrorMessage("")} />
    </div>);
}

export default InterviewActivationButton;