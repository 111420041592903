import { useEffect, useState } from "react";
import { GetInterviews } from "../../api/Firestore";
import InterviewGrid from "./InterviewGrid";
import { getInterviewLink } from "../interview/ChatInterviewUtils";
import { generateInterviewSummaries, generateProjectInsight } from "../../api/ChatCompletion";
import ReactMarkdown from 'react-markdown';
import './ProjectResults.css';

function ProjectResults({ projectId, savedProject }) {

    const [interviews, setInterviews] = useState([]);

    useEffect(() => {
        if (savedProject !== null && projectId !== null) {
            GetInterviews(projectId).then(i => {
                setInterviews(i);
                // Trigger data generation in case there's additional work to be done.
                generateInterviewSummaries(projectId);
                generateProjectInsight(projectId);
            }).catch(err => "Something went wrong accessing your interviews: " + err);
        }
    }, [projectId, savedProject]);

    const getLink = () => {
        if (savedProject === null) {
            return "Activate the project to generate the interview link";
        }
        return getInterviewLink(savedProject.projectDetails.interviewLinkId);
    }

    const hasProjectInsight = () => {
        return savedProject && savedProject.insight && savedProject.insight.summary && savedProject.insight.summary !=="";
    }

    const getProjectInsight = () => {
        if (hasProjectInsight()) {
            return savedProject.insight.summary;
        }
    }

    const responseTitle = (
        <div>Interviews
            <span style={{ color: "#aaa" }}> ({interviews.length}) </span>
        </div>
    );

    const projectInsight = hasProjectInsight() ?
        (<div className="project-summary" style={{lineHeight: "130%"}}>
            <ReactMarkdown>{getProjectInsight()}</ReactMarkdown>
        </div>) :
        (<p>Insights will be generated once several interviews have been conducted.</p>);

    const interviewLink = (
        <div>
            {savedProject === null &&
                <p> Activate the project to generate the interview link</p>
            }
            {savedProject !== null &&
                <div>
                    <span style={{ color: "#5FA598", fontWeight: "500" }}>{getLink()}</span>
                    <p>Share this link with the users you want to interview. Once they complete their interviews, their responses will appear below.</p>
                </div>
            }
        </div>
    );

    return (
        <div className="container">
            <h2 style={{ marginBottom: "0.5em" }}>Interview link</h2>
            {interviewLink}
            <h2 style={{ marginTop: "2rem" }}>Interview Insights</h2>
            {projectInsight}
            <h2 style={{ marginTop: "2rem" }}>{responseTitle}</h2>
            {interviews.length > 0 &&
                <div>
                    <InterviewGrid projectId={projectId} interviews={interviews} />
                </div>}
            {interviews.length === 0 &&
                <p>Responses will appear here as users are interviewed</p>}
        </div>)
}

export default ProjectResults;
