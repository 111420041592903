import SignInScreen from "../components/auth/SignInScreen.js";
import './LandingPage.css';
import './Home.css';
import Logo from "../assets/logo_white.svg";
import BottomDecoration from "../assets/bottom_decoration.svg";
import TrainSS from "../assets/train_ss.svg"
import TestSS from "../assets/test_ss.svg"
import EmailSS from "../assets/email_ss.svg"
import SummarySS from "../assets/summary_ss.svg"
import BrowserActions from "../assets/browser_actions.svg"

import LandingInterview1 from "../assets/landing_interview_1.png"
import LandingInterview2 from "../assets/landing_interview_2.png"
import LandingInterview3 from "../assets/landing_interview_3.png"
import LandingInterview4 from "../assets/landing_interview_4.png"

import { Dialog } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SaveDemoTranscript, CreateDemoTranscriptID } from '../api/Firestore';
import { chatCompletionForDemoWithRetry } from '../api/ChatCompletion';

import List from '@mui/material/List';
import Send from "../assets/send.svg";
import { getFirebaseAuth } from "../components/firebase/firebase.js";

function LandingPage() {

    const [open, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userInput, setUserInput] = useState([]);
    const [allowUserInput, setAllowUserInput] = useState(true);
    const [demoTranscriptID, setDemoTranscriptID] = useState('');

    const auth = getFirebaseAuth();
    const navigate = useNavigate();

    const navigateToPrivacyPolicy = () => {
        navigate('./privacy_policy');
    };

    const navigateToTerms = () => {
        navigate('./terms');
    };

    const handleMailtoClick = () => {
        window.location.href = 'mailto:contact@pansophic.co';
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogin = () => {
        navigate('/home');
    };


    const [conversation, setConversation] = useState([
        { "role": "assistant", "content": "Welcome to Pansophic! I'm an AI agent trained to interview potential users about their user interview needs and to demonstrate how Pansophic can interview your users. Do you collect user feedback as a part of your job?" }]);

    const generateAIResponse = (conversation) => {
        chatCompletionForDemoWithRetry(conversation).then(r => {
            setConversation((prevMessages) => {
                setAllowUserInput(true);
                return [...prevMessages, { "role": "assistant", "content": r }];
            });
        }).catch(err => {
            console.log("Completion did not work: ");
            setAllowUserInput(true);
        });
    };

    useEffect(() => {
        const newDemoTranscriptID = CreateDemoTranscriptID();
        setDemoTranscriptID(newDemoTranscriptID);
    }, []);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setLoggedIn(true);
            } else {
                setLoggedIn(false);
            }
        });
    }, [auth]);

    const getButton = () => {
        return loggedIn ? loggedInButton : loggedOutButton;
    };

    const loggedInButton =  (<div style={{ width: '150px', display: 'flex', justifyContent: 'right' }}>
    <button className="signin-button" onClick={handleLogin}>Dashboard</button>
</div>);

    const loggedOutButton = (<div style={{ width: '150px', display: 'flex', justifyContent: 'right' }}>
        <button className="signin-button" onClick={handleOpen}>Sign in</button>
    </div>);

    const submitInput = () => {
        if (!isValidUserInput(userInput)) {
            return;
        }
        if (allowUserInput) {
            setAllowUserInput(false);
            let input = sanitiseInput(userInput);
            setConversation((prevMessages) => {
                return [
                    ...prevMessages,
                    { "role": "user", "content": input },
                ]
            });
            SaveDemoTranscript(demoTranscriptID, conversation);
            generateAIResponse([
                ...conversation,
                { "role": "user", "content": input },
            ]);
            setUserInput("");
        }
    }

    const isValidUserInput = (input) => {
        if (input === '') return false;
        if (input === '\n') return false;
        return true;
    }

    const sanitiseInput = (input) => {
        return input.replace(/^\s+|\s+$/g, '');
    }

    const demoRef = useRef(null);
    const howRef = useRef(null);
    const pricingRef = useRef(null);

    const scrollToSection = (ref) => {
        const offset = 60;
        const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    };

    return (
        <div className="landing-body">
            <div className="landing-header">
                <a href="./"><img className="landing-logo" src={Logo} alt="Logo" /></a>
                <div className="landing-links">
                    <button className="header-button" style={{ cursor: "pointer" }} onClick={() => scrollToSection(demoRef)}>DEMO</button>
                    <button className="header-button" style={{ cursor: "pointer" }} onClick={() => scrollToSection(howRef)}>HOW IT WORKS</button>
                    <button className="header-button" style={{ cursor: "pointer" }} onClick={() => scrollToSection(pricingRef)}>PRICING</button>
                </div>
                {getButton()}
                <Dialog open={open} onClose={handleClose}>
                    <SignInScreen />
                </Dialog>
            </div>
            <div className="landing-container">
                <div className="landing-hero">
                    <div className="landing-left-column">
                        <h1>Automate user interviews</h1>
                        <p>Pansophic's AI agents interview your users and summarize their feedback</p>
                        <button className="custom-button" style={{ backgroundColor: "#00B897" }} onClick={handleOpen}>Try for free</button>
                    </div>
                    <div className="landing-right-column">
                        <img className="landing-interview-images" alt="inteview example" src={LandingInterview1} />
                        <img className="landing-interview-images" alt="inteview example" src={LandingInterview2} />
                        <img className="landing-interview-images" alt="inteview example" src={LandingInterview3} />
                        <img className="landing-interview-images" alt="inteview example" src={LandingInterview4} />

                    </div>
                </div>

                <div id="Demo" ref={demoRef} className="landing-demo-section">
                    <div className="landing-demo-section-top">
                        <img src={BrowserActions} width={'42px'} alt="Browser buttons" />
                        <p>Demo</p>
                        <div width={'42px'} />
                    </div>
                    <div className="landing-demo-section-main">
                        <div className='landing-demo-container'>
                            <div>
                                <List className="landing-demo-chat-container">
                                    {conversation.map((m, index) => (
                                        <li key={index} className={m.role === "user" ? "right-align" : ""}>
                                            <div className='sender-title'>{m.role === "user" ? "You" : "Interviewer"}</div>
                                            <div className={m.role === "user" ? "user-bubble" : "chatbot-bubble"}>
                                                <span>{m.content}</span>
                                            </div>
                                        </li>
                                    ))}
                                    {!allowUserInput && <div>
                                        <div className='sender-title'>Interviewer</div>
                                        <div className="chatbot-bubble">
                                            <div className="typing-indicator">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>}
                                </List>


                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <form style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <textarea
                                        className='interview-input-box'
                                        placeholder="Send a message..."
                                        aria-label="new user input"
                                        value={userInput}
                                        rows={2}
                                        onChange={(e) => {
                                            if (e.target.value !== '\n') {
                                                setUserInput(e.target.value)
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                submitInput();
                                            }
                                        }}
                                    />
                                    <input
                                        type="image"
                                        src={Send}
                                        disabled={!allowUserInput}
                                        className='send-button'
                                        aria-label="send message"
                                        onClick={() => {
                                            submitInput();
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="landing-benefits-section">
                <h1>Benefits</h1>

                <div className="landing-benefits">
                    <div ref={pricingRef} className="landing-benefits-boxes">
                        <h3>24/7 Availability</h3>
                        <p>Your users don't need to schedule their interviews or accommodate schedules and varying time zones. They can take their interviews at their convenience.</p>
                    </div>

                    <div ref={pricingRef} className="landing-benefits-boxes">
                        <h3>Multilingual Support</h3>
                        <p>Pansophic's agents can interview your users in their preferred language and provide you summaries and insights in English.</p>
                    </div>

                    <div ref={pricingRef} className="landing-benefits-boxes">
                        <h3>Scalability</h3>
                        <p>Pansophic can conduct as many interviews as you'd like simultaneously, allowing for rapid data collection from a large number of users without facing resource constraints.</p>
                    </div>
                    
                    <div ref={pricingRef} className="landing-benefits-boxes">
                        <h3>Automated Analysis</h3>
                        <p>Pansophic automatically analyzes feedback to extract insights, identify patterns, sentiments, and common themes and provides these in a consolidated manner, instantly.</p>
                    </div>

                    <div ref={pricingRef} className="landing-benefits-boxes">
                        <h3>Consistency</h3>
                        <p>AI agents maintain a consistent interview experience, asking questions in a similar manner to every user and reducing the effects of interviewer bias.</p>
                    </div>

                </div>

                </div>

                <div id="How" ref={howRef} className="landing-how-section">
                    <h1>How it works</h1>
                    <p>Provide the Pansophic AI agent with your research goals and an overview of your product, then let it interview your users and summarize insights.</p>
                    <div className="landing-how-columns">
                        <div className="landing-how-left-column">
                            <img className="landing-how-image" src={TrainSS} alt="Training screenshot" />
                        </div>
                        <div className="landing-how-right-column">
                            <h2>1. Train the agent</h2>
                            <div className="landing-how-right-column-text">Provide the Pansophic AI agent with your research goals and an overview of your product, then let it interview your users and summarize insights.</div>
                        </div>
                    </div>

                    <div className="landing-how-columns">

                        <div className="landing-how-right-column-reverse">
                            <h2>2. Test the agent</h2>
                            <div className="landing-how-right-column-text">Conduct test interviews with the agent to ensure it leads interviews in the manner you want.</div>
                        </div>
                        <div className="landing-how-left-column">
                            <img className="landing-how-image" src={TestSS} alt="Testing screenshot" />
                        </div>
                        <div className="landing-how-right-column-reverse-narrow">
                            <h2>2. Test the agent</h2>
                            <div className="landing-how-right-column-text">Conduct test interviews with the agent to ensure it leads interviews in the manner you want.</div>
                        </div>
                    </div>
                    <div className="landing-how-columns">
                        <div className="landing-how-left-column">
                            <img className="landing-how-image" src={EmailSS} alt="Email screenshot" />
                        </div>
                        <div className="landing-how-right-column">
                            <h2>3. Share with your users</h2>
                            <div className="landing-how-right-column-text">Send the interview link to your users, who can instantly chat with the agent you trained at any moment they choose, no interview scheduling required.</div>
                        </div>
                    </div>

                    <div className="landing-how-columns">
                        <div className="landing-how-right-column-reverse">
                            <h2>4. Review responses</h2>
                            <div className="landing-how-right-column-text">Review insights from your project as well as individual interview summaries and transcripts recorded by the agent.</div>
                        </div>
                        <div className="landing-how-left-column">
                            <img className="landing-how-image" src={SummarySS} alt="Rectangle" />
                        </div>
                        <div className="landing-how-right-column-reverse-narrow">
                            <h2>4. Review responses</h2>
                            <div className="landing-how-right-column-text">Review insights from your project as well as individual interview summaries and transcripts recorded by the agent.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="landing-pricing-section">
                <h1>Pricing plans</h1>
                <p>First 5 interviews on any plan are free, subscriptions only start afterwards.</p>
                <div className="landing-pricing">
                    <div id="Pricing" ref={pricingRef} className="landing-pricing-boxes">
                        <h3>Lite</h3>
                        <p>10 interviews included each month, $5 per interview afterwards.</p>
                        <h2>$25<span style={{fontSize: "14px"}}>/month</span></h2>
                        <button className="custom-button" style={{ backgroundColor: "#00B897", margin: "2rem auto 0 auto" }} onClick={handleOpen}>Try for free</button>

                    </div>

                    <div id="Pricing" ref={pricingRef} className="landing-pricing-boxes">
                        <h3>Pro</h3>
                        <p>30 interviews included each month, $3 per interview after that.</p>
                        <h2>$60<span style={{fontSize: "14px"}}>/month</span></h2>
                        <button className="custom-button" style={{ backgroundColor: "#00B897", margin: "2rem auto 0 auto" }} onClick={handleOpen}>Try for free</button>

                    </div>

                    <div id="Pricing" ref={pricingRef} className="landing-pricing-boxes">
                        <h3>Enterprise</h3>
                        <p>Please reach out to us at contact@pansophic.co if you need more interviews or a different pricing configuration. </p>
                        <h2 style={{margin: "2rem auto 0 auto", textAlign: "center" }}>Contact us</h2>
                    </div>
                    
                </div>

                    
            </div>

            <img className="bottom-decoration" alt="bottom decoration" src={BottomDecoration} />
            <div className="landing-footer">
                <div className="landing-footer-container">
                    <div className="landing-footer-cc">
                        © 2023 Pansophic. All rights reserved.
                    </div>
                    <div className="landing-footer-links">
                        <button onClick={handleMailtoClick}>Contact</button>
                        <button onClick={navigateToPrivacyPolicy}>Privacy Policy</button>
                        <button onClick={navigateToTerms}>Terms of Service</button>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default LandingPage;