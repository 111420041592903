import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../components/firebase/firebase';
import { RetryWithExponentialBackoff } from './common';

const functions = getFirebaseFunctions();

export const chatCompletionWithRetry = async (pid, productOverview, interviewGoal, convo) => {
  return await RetryWithExponentialBackoff(chatCompletion, { pid, productOverview, interviewGoal, convo });
};

const chatCompletion = async ({ pid, productOverview, interviewGoal, convo }) => {
  const askAssistant = httpsCallable(functions, 'askAssistant');
  return askAssistant({ projectid: pid, productOverview: productOverview, interviewGoal: interviewGoal, conversation: convo })
    .then((result) => {
      return result.data;
    }).catch((error) => {
      throw error;
    });
};

export const createInterviewSummaryWithRetry = async (pid, convo) => {
  return await RetryWithExponentialBackoff(createInterviewSummary, { pid, convo });
};

export const createInterviewSummary = async ({ pid, convo }) => {
  const summariseConversation = httpsCallable(functions, 'summariseConversation');
  return summariseConversation({ projectid: pid, conversation: convo })
    .then((result) => {
      return result.data;
    }).catch((error) => {
      throw error;
    });
};

export const chatCompletionForDemoWithRetry = async (convo) => {
  return await RetryWithExponentialBackoff(chatCompletionForDemo, { convo });
};

const chatCompletionForDemo = async ({ convo }) => {
  const askDemoAssistant = httpsCallable(functions, 'askDemoAssistant');
  return askDemoAssistant({ conversation: convo })
    .then((result) => {
      return result.data;
    }).catch((error) => {
      throw error;
    });
};

export const generateInterviewSummaries = async (pid) => {
  const generateInterviewSummaries = httpsCallable(functions, 'generateInterviewSummaries');
  return generateInterviewSummaries({ projectid: pid })
    .then((result) => {
      return result.data;
    }).catch((error) => {
      throw error;
    });
};

export const generateProjectInsight = async (pid) => {
  const generateProjectInsight = httpsCallable(functions, 'generateProjectInsight');
  return generateProjectInsight({ projectid: pid })
    .then((result) => {
      return result.data;
    }).catch((error) => {
      throw error;
    });
};