import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions } from 'firebase/functions';

const config = GetFirebaseConfig();
const app = firebase.initializeApp(config);
if (window.location.hostname === "localhost") {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = "2a3362b0-71c9-4661-be56-5776d7f7943a";
} else{
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = undefined;
}
initializeFirebaseAppCheck();

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
// setup default analytics
getAnalytics(app);

export function GetFirebaseConfig() {
    return {
        apiKey: "AIzaSyBUdTSmp7jlxtDgNocTt1Oa2XNldhnIavc",
        authDomain: "pansophic-19b40.firebaseapp.com",
        projectId: "pansophic-19b40",
        storageBucket: "pansophic-19b40.appspot.com",
        messagingSenderId: "178734966004",
        appId: "1:178734966004:web:fc2c48cb92d61843276aa1",
        measurementId: "G-H135VQN7NY"
    };
}

export function getFirebaseApp() {
    return app;
}

export function getFirebaseAuth() {
    return auth;
}

export function getFirebaseFirestore() {
    return db;
}

export function getFirebaseFunctions() {
    return functions;
}

function initializeFirebaseAppCheck() {
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider('6LfGF2YmAAAAAGAMj8YTyh4uZWWxn2KYzBezvfMd'),
        isTokenAutoRefreshEnabled: true
    });
}
