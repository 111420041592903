import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import { chatCompletionWithRetry } from '../api/ChatCompletion';
import { useSearchParams } from 'react-router-dom';
import Logo from "../assets/logo.svg";
import Send from "../assets/send.svg";
import { PermanentDialogue, ActionDialogueAlternate } from '../components/common/Dialogue';

import './ChatInterview.css';

function TestInterview() {
    const [searchParams] = useSearchParams();
    const dataFetchedRef = useRef(false);
    const pid = searchParams.get("projectId");
    const [allowUserInput, setAllowUserInput] = useState(false);
    const [userInput, setUserInput] = useState([]);
    const [showEndDialogue, setShowEndDialogue] = useState(false);
    const [showEndConfirmationDialog, setshowEndConfirmationDialog] = useState(false);
    const [conversation, setConversation] = useState([]);

    const generateAIResponse = (conversation) => {
        chatCompletionWithRetry(pid, searchParams.get("productOverview"), searchParams.get("interviewGoal"), conversation).then(r => {
            setConversation((prevMessages) => {
                setAllowUserInput(true);
                return [...prevMessages, { "role": "assistant", "content": r }];
            });
        }).catch(err => {
            // TODO(pozlen): Figure out the recovery case from API errors. For now just
            // enable user input so that they can resume.
            console.log("Completion did not work: ");
            setAllowUserInput(true);
        });
    };

    const handleEndDialogOpen = () => {
        setshowEndConfirmationDialog(true);
    };

    const handleEndDialogCancel = () => {
        setshowEndConfirmationDialog(false);
    };

    const handleEndDialogProceed = () => {
        setshowEndConfirmationDialog(false);
        endInterview();
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        generateAIResponse([]);
        dataFetchedRef.current = true;
    },[]);

    const submitInput = () => {
        if (!isValidUserInput(userInput)) {
            return;
        }
        if (allowUserInput) {
            setAllowUserInput(false);
            let input = sanitiseInput(userInput);
            setConversation((prevMessages) => {
                return [
                    ...prevMessages,
                    { "role": "user", "content": input },
                ]
            });
            generateAIResponse([
                ...conversation,
                { "role": "user", "content": input },
            ]);
            setUserInput("");
        }
    }
    const isValidUserInput = (input) => {
        if (input.toString().trim().length === 0) return false;
        if (input === '\n') return false;
        return true;
    }

    const endInterview = async () => {
        setShowEndDialogue(true);
        setUserInput(false);
    }

    const sanitiseInput = (input) => {
        return input.replace(/^\s+|\s+$/g, '');
    }

    return (
        <div className='interview-body'>
            <div className='interview-header'>
                <img className="interview-logo" src={Logo} alt="Logo" />
            </div>
            <div className='interview-container'>
                <div>
                    <List className="interview-chat-container">
                        {conversation.map((m, index) => (
                            <li key={index} className={m.role === "user" ? "right-align" : ""}>
                                <div className='sender-title'>{m.role === "user" ? "You" : "Interviewer"}</div>
                                <div className={m.role === "user" ? "user-bubble" : "chatbot-bubble"}>
                                    <span>{m.content}</span>
                                </div>
                            </li>
                        ))}
                        {!allowUserInput && <div>
                            <div className='sender-title'>Interviewer</div>
                            <div className="chatbot-bubble">
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>}
                        <div className='interview-chat-top-overlay' />
                        <div className='interview-chat-bottom-overlay' />
                    </List>
                </div>

                <PermanentDialogue open={showEndDialogue}
                    title="Interview complete"
                    content="Thank you for taking the time to provide your feedback! Feel free to close the tab." />
                <ActionDialogueAlternate open={showEndConfirmationDialog}
                    title="End interview?"
                    content="Please confirm if you'd like to end the interview."
                    cancel={handleEndDialogCancel}
                    confirm={handleEndDialogProceed}
                    cancelText="Cancel"
                    confirmText="Confirm"
                />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <form style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <textarea
                            className='interview-input-box'
                            placeholder="Send a message..."
                            aria-label="new user input"
                            value={userInput}
                            rows={3}
                            onChange={(e) => {
                                if (e.target.value !== '\n') {
                                    setUserInput(e.target.value)
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submitInput();
                                }
                            }}
                        />
                        <input
                            type="image"
                            src={Send}
                            disabled={!allowUserInput || isValidUserInput(userInput) === false}
                            className='send-button'
                            aria-label="send message"
                            onClick={() => {
                                console.log(allowUserInput);
                                submitInput();
                            }}
                        />
                    </form>
                    <button className="text-button" style={{ marginTop: '2rem', filter: "saturate(0)" }} onClick={handleEndDialogOpen}>End interview</button>
                </div>
            </div>
        </div>
    );
}



export default TestInterview;
