import React from 'react';
import { useNavigate } from "react-router-dom";
import Table from '../home/InterviewsTable';

const InterviewGrid = ({ projectId, interviews }) => {
    const navigate = useNavigate();
    const columns = React.useMemo(
        () => [
            { Header: '#', accessor: 'index' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Email', accessor: 'email'},
            {
                Header: 'Date',
                accessor: row => {
                    return new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }).format(row.timestamp);
                },
            },
            {
                Header: 'Time',
                accessor: row => {
                    return new Intl.DateTimeFormat('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                    }).format(row.timestamp);
                },
            },
            // { Header: 'Keywords', accessor: 'projectDetails.keywords' },
        ],
        []
    );

    const clickedOnAnInterview = (i) => {
        console.log("navigating");
        navigate('/project/' + projectId + "/results/" + i.interviewId, {
            state: {
              projectId: projectId,
              iid: i.interviewId
            },
        });
    }

    /*TODO: Add pagination*/
    const table = (<Table columns={columns} data={interviews} rowSelectFn={clickedOnAnInterview} />);

    return table;
}
export default InterviewGrid;