import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton } from '@material-ui/core';

import '../../pages/ResearchProject.css';

export const NameAndEmailDialog = ({ open, title, content, cancel, confirm, email, name }) => {
  const [nameValue, setNameValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');

  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{title}</Box>
          <Box>
            <IconButton onClick={cancel}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '1rem' }}>{content}</DialogContentText>
        {name && (
          <TextField
            autoFocus
            margin="dense"
            label="Full name"
            type="text"
            fullWidth
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
        )}
        {email && (
          <TextField
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions style={{ padding: "20px" }}>
        <button className="text-button" style={{ color: '#666' }} onClick={cancel} autoFocus>Skip</button>
        <button className="text-button" style={{ marginLeft: "30px", color: '#00B897' }} onClick={() => confirm(nameValue, emailValue)}>Submit</button>
      </DialogActions>
    </Dialog>
  );
};
