import React from 'react';

import { useState, useEffect } from "react";
import moment from "moment";
import "./Home.css";
import './Account.css';
import './InterviewResult.css';
import { GetInterviewTranscript, SaveInterviewSummary } from '../api/Firestore';
import { useLocation, useOutletContext } from 'react-router-dom';
import { createInterviewSummaryWithRetry } from '../api/ChatCompletion';

import Loader from '../assets/summary_loader.gif';

function InterviewResult() {
  const [interview, setInterview] = useState([]);
  const [loadedInterview, setLoadedInterview] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPaidCustomer, setHeader] = useOutletContext();

  const { state } = useLocation();
  const projectId = state.projectId;
  const iid = state.iid;

  useEffect(() => {
    GetInterviewTranscript(projectId, iid).then(i => {
      setInterview(i);
      setLoadedInterview(true);
      setHeader("Interview Details");
    }).catch(err => {
      console.log("Can't load transcript: " + err.message);
    });
  }, [projectId, iid, setHeader]);

  useEffect(() => {
    if (shouldGenerateInterviewSummary()) {
      createInterviewSummaryWithRetry(projectId, interview.transcript).then((summary) => {
        SaveInterviewSummary(iid, projectId, summary).then(() => {
          setInterview({ ...interview, summary: summary });
        });
      }).catch(err => { console.log("Can't generate a summary: " + err.message) });
    }
  }, [loadedInterview, projectId, iid, interview]);

  const shouldGenerateInterviewSummary = () => {
    if (loadedInterview && interview !== undefined) {
      return isTheInterviewOver() && // only generate interview summary if the interview has ended.
        (interview.summary === undefined || interview.summary === "");
    }
    return false;
  }

  const isTheInterviewOver = () => {
    if (loadedInterview && interview !== undefined && interview.timestamp !== undefined) {
      var currentTime = moment().utc();
      var timestampToCheck = moment.utc(interview.timestamp);
      var hoursDiff = currentTime.diff(timestampToCheck, 'hours');
      return (hoursDiff >= 1);
    }
    return false;
  }

  const getSummarySection = () => {
    if (interview.summary !== undefined) {
      return (<p style={{ lineHeight: "180%" }}>{interview.summary}</p>);
    } else if (!isTheInterviewOver()) {
      return (<p style={{ lineHeight: "180%" }}>We're waiting for the interview to conclude before generating the insights...</p>);
    }
    return (<div style={{ display: "flex", flexDirection: "row" }}>
      <p style={{ lineHeight: "180%" }}>We're working hard to generate you a summary, hold tight...</p>
      <img src={Loader} height="32px" alt="Loading..." style={{ margin: "8px" }} />
    </div>);
  }

  return (loadedInterview &&
    <div>
      <div className="container">
        <div>
          <h2 style={{ margin: "2rem 0 1rem 0" }}>Summary</h2>
          {getSummarySection()}
        </div>
        <h2 style={{ margin: "2rem 0 1rem 0" }}>Transcript</h2>
        <div className="ir-chat-container">
          {interview.transcript.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              <span style={{ textTransform: "capitalize" }}>{message.role}</span>: {message.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default InterviewResult;
