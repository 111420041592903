export function Instructions() {
    return (
    <div>
        <h2>Instructions</h2>
        <ol style={{lineHeight: "180%"}}>
            <li>Configure your interview</li>
            <li>Test the interviewer agent and update configuration if needed</li>
            <li>Activate the interview</li>
            <li>Share the interview link with your users</li>
            <li>Review interview transcripts and summaries on the results tab</li>
        </ol>
    </div>);
}