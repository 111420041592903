import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function MarkdownToHtml() {
  const markdownText = `
  Your privacy is critically important to us. At Pansophic, we have a few fundamental principles:

  *   We are thoughtful about the personal information we ask you to provide and the personal information that we collect about you through the operation of our services.
  *   We store personal information for only as long as we have a reason to keep it.
  *   We aim to make it as simple as possible for you to control what information on your website is shared publicly (or kept private), indexed by search engines, and permanently deleted.
  *   We help protect you from overreaching government demands for your personal information.
  *   We aim for full transparency on how we gather, use, and share your personal information.
  
  Below is our Privacy Policy, which incorporates and clarifies these principles.
  
  ### What This Policy Covers
  
  This Privacy Policy applies to information that we collect about you when you use our website (Pansophic.co). Throughout this Privacy Policy we’ll refer to our website and software collectively as “Services.” Below we explain how we collect, use, and share information about you, along with the choices that you have with respect to that information.
  
  ### Information We Collect
  
  We only collect information about you if we have a reason to do so — for example, to provide our Services, to communicate with you, or to make our Services better. We collect this information from three sources: if and when you provide information to us, automatically through operating our Services, and from outside sources. Let’s go over the information that we collect.
  
  #### _Information You Provide to Us_
  
  It’s probably no surprise that we collect information that you provide to us directly. Here are some examples:
  
  *   **Basic account information:** We ask for basic information from you in order to set up your account. For example, we require individuals who sign up for a Pansophic.ci account to provide an email address and password, along with a name — that’s it. 
  *   **Content information:** You might provide us with information about you or your business through the research projects you create and the interview agents you train.
  *   **Communications with us:** You may also provide us with information when you respond to surveys or communicate with our interview agents. When you communicate with us via form, email, or otherwise, we store a copy of our communications.
  
  #### _Information We Collect Automatically_
  
  We also collect some information automatically:
  
  *   **Log information:** Like most online service providers, we collect information that web browsers, mobile devices, and servers typically make available, including the browser type, IP address, unique device identifiers, language preference, referring site, the date and time of access, operating system, and mobile network information. We collect log information when you use our Services — for example, when you create or make changes to your research projects on Pansophic.ci.
  *   **Transactional information:** When you make a purchase through our Services, we collect information about the transaction, such as product details, purchase price, and the date and location of the transaction.
  *   **Usage information:** We collect information about your usage of our Services. For example, we collect information about the actions users perform on our website — in other words, who did what and when. We also collect information about what happens when you use our Services (e.g., page views, interview configuration details, and interview transcripts, along with information about your device (e.g., screen size, name of cellular network, and mobile device manufacturer). We use this information to, for example, provide our Services to you, get insights on how people use our Services so we can make our Services better, and understand and make predictions about user retention.
  *   **Location information:** We may determine the approximate location of your device from your IP address. We collect and use this information to, for example, calculate how many people visit our Services from certain geographic regions. 
  *   **Information from cookies & other technologies:** A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Pixel tags (also called web beacons) are small blocks of code placed on websites and emails. Pansophic uses cookies and other technologies like pixel tags to help us identify and track visitors, usage, and access preferences for our Services, as well as track and understand email campaign effectiveness and to deliver targeted ads.
  
  #### _Information We Collect from Other Sources_
  
  We may also get information about you from other sources. For example: 
  
  *   **Third Party Login:** If you create or log in to your Pansophic.co account through another service (like Google) we’ll receive associated login information (e.g. a connection token, your username, your email address)
  
  The information we receive depends on which services you use or authorize and what options are available. 
  
  ### How and Why We Use Information
  
  #### _Purposes for Using Information_
  
  We use information about you for the purposes listed below:
  
  *   **To provide our Services.** For example, to set up and maintain your account, host your website, backup and restore your website, provide customer service, process payments and orders, and verify user information.
  *   **To ensure quality, maintain safety, and improve our Services.** For example, by providing automatic upgrades and new versions of our Services. Or, for example, by monitoring and analyzing how users interact with our Services so we can create new features that we think our users will enjoy and that will help them create and manage websites more efficiently or make our Services easier to use.
  *   **To market our Services and measure, gauge, and improve the effectiveness of our marketing.** For example, by targeting our marketing messages to groups of our users (like those who have been users for a certain length of time), advertising our Services, analyzing the results of our marketing campaigns (like how many people conducted paid interviews after receiving a marketing message), and understanding and forecasting user retention.
  *   **To protect our Services, our users, and the public.** For example, by detecting security incidents; detecting and protecting against malicious, deceptive, fraudulent, or illegal activity; fighting spam; complying with our legal obligations; and protecting the rights and property of Automattic and others, which may result in us, for example, declining a transaction or terminating Services.
  *   **To fix problems with our Services.** For example, by monitoring, debugging, repairing, and preventing issues.
  *   **To customize the user experience.** For example, to personalize your experience by serving you relevant notifications and advertisements for our Services.
  *   **To communicate with you.** For example, by emailing you to ask for your feedback, share tips for getting the most out of our products, or keep you up to date on Pansophic; or texting you to verify your payment. If you don’t want to hear from us, you can opt out of marketing communications at any time. (If you opt out, we’ll still send you important updates relating to your account.
  
  #### _Legal Bases for Collecting and Using Information_
  
  A note here for those in the European Union about our legal grounds for processing information about you under EU data protection laws, which is that our use of your information is based on the grounds that: (1) The use is necessary in order to fulfill our commitments to you under the applicable terms of service or other agreements with you or is necessary to administer your account — for example, in order to enable access to our website on your device or charge you for a paid plan; or (2) The use is necessary for compliance with a legal obligation; or (3) The use is necessary in order to protect your vital interests or those of another person; or (4) We have a legitimate interest in using your information — for example, to provide and update our Services; to improve our Services so that we can offer you an even better user experience; to safeguard our Services; to communicate with you; to measure, gauge, and improve the effectiveness of our advertising; and to understand our user retention and attrition; to monitor and prevent any problems with our Services; and to personalize your experience; or (5) You have given us your consent — for example before we place certain cookies on your device and access and analyze them later on.
  
  ### Sharing Information
  
  #### _How We Share Information_
  
  We share information about you in limited circumstances, and with appropriate safeguards on your privacy. These are spelled out below:
  
  *   **Subsidiaries and independent contractors:** We may disclose information about you to our subsidiaries and independent contractors who need the information to help us provide our Services or process the information on our behalf. We require our subsidiaries and independent contractors to follow this Privacy Policy for any personal information that we share with them.
  *   **Third-party vendors:** We may share information about you with third-party vendors who need the information in order to provide their services to us, or to provide their services to you or your site. This includes vendors that help us provide our Services to you (like Stripe, which powers Pansophic payments, payment providers that process your credit and debit card information, fraud prevention services that allow us to analyze fraudulent payment transactions, cloud storage services, postal and email delivery services that help us stay in touch with you, customer chat and email support services that help us communicate with you, registrars, registries, data escrow services that allow us to provide domain registration services); those that assist us with our marketing efforts (e.g., by providing tools for identifying a specific marketing target group or improving our marketing campaigns, and by placing ads to market our services); those that help us understand and enhance our Services (like analytics providers); those that make tools to help us run our operations (like programs that help us with task management, scheduling, word processing, email and other communications, and collaboration among our teams); other third-party tools that help us manage operations who may need information about you in order to, for example, provide technical or other support services to you. We require vendors to agree to privacy commitments in order to share information with them.
  *   **Legal and regulatory requirements:** We may disclose information about you in response to a subpoena, court order, or other governmental request.
  *   **To protect rights, property, and others:** We may disclose information about you when we believe in good faith that disclosure is reasonably necessary to protect the property or rights of Pansophic, third parties, or the public at large.
  *   **Business transfers:** In connection with any merger, sale of company assets, or acquisition of all or a portion of our business by another company, or in the event that Pansophic goes out of business or enters bankruptcy, user information would likely be one of the assets that is transferred or acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to apply to your information and the party receiving your information may continue to use your information, but only consistent with this Privacy Policy.
  *   **With your consent:** We may share and disclose information with your consent or at your direction. For example, we may share your information with third parties when you authorize us to do so.
  *   **Aggregated or de-identified information:** We may share information that has been aggregated or de-identified, so that it can no longer reasonably be used to identify you. For instance, we may publish aggregate statistics about the use of our Services, or share a hashed version of your email address to facilitate customized ad campaigns on other platforms.
  *   **Published support requests:** If you send us a request for assistance (for example, via a support email or one of our other feedback mechanisms), we reserve the right to publish that request in order to clarify or respond to your request, or to help us support other users.
  
  #### _Information Shared By You_
  
  Information that you use to train interview agents may be shared by the agent through user interviews.  Please keep this in mind when deciding what information you would like to provide interview agents.
  
  ### How Long We Keep Information
  
  We generally discard information about you when it’s no longer needed for the purposes for which we collect and use it — described in the section above on How and Why We Use Information — and we’re not legally required to keep it. 
  
  For example, we keep web server logs that record information about a visitor to one of Automattic’s websites, like the visitor’s IP address, browser type, and operating system, for approximately 30 days. We retain the logs for this period of time in order to, among other things, analyze traffic to Automattic’s websites and investigate issues if something goes wrong on one of our websites. 
  
  As another example, when you delete a project, the deleted content may remain on our backups and caches until purged.
  
  ### Security
  
  While no online service is 100% secure, we work very hard to protect information about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. We monitor our Services for potential vulnerabilities and attacks.
  
  ### Choices
  
  You have several choices available when it comes to information about you:
  
  *   **Limit the information that you provide:** If you have an account with us, you can choose not to provide the optional account information, profile information, and transaction and billing information. Please keep in mind that if you do not provide this information, certain features of our Services — for example, conducting user interviews — may not be accessible.
  *   **Opt out of marketing communications:** You may opt out of receiving promotional communications from us. Just follow the instructions in those communications or let us know. If you opt out of promotional communications, we may still send you other communications, like those about your account and legal notices.
  *   **Delete your account:** While we’d be very sad to see you go, you can delete your account if you no longer want to use our Services. Please keep in mind that we may continue to retain your information after closing your account, as described in How Long We Keep Information above — for example, when that information is reasonably needed to comply with (or demonstrate our compliance with) legal obligations such as law enforcement requests, or reasonably needed for our legitimate business interests.
  
  ### Your Rights
  
  If you are located in certain parts of the world, including California and countries that fall under the scope of the European General Data Protection Regulation (aka the “GDPR”), you may have certain rights regarding your personal information, like the right to request access to or deletion of your data.
  
  #### _European General Data Protection Regulation (GDPR)_
  
  If you are located in a country that falls under the scope of the GDPR, data protection laws give you certain rights with respect to your personal data, subject to any exemptions provided by the law, including the rights to:
  
  *   Request access to your personal data;
  *   Request correction or deletion of your personal data;
  *   Object to our use and processing of your personal data;
  *   Request that we limit our use and processing of your personal data; and
  *   Request portability of your personal data.
  
  You also have the right to make a complaint to a government supervisory authority.
  
  #### _US Privacy Laws_
  
  Laws in some US states, including California, Colorado, Connecticut, Utah, and Virginia, require us to provide residents with additional information about the categories of personal information we collect and share, where we get that personal information, and how and why we use it. You’ll find that information in this section (if you are a California resident, please note that this is the Notice at Collection we are required to provide you under California law).
  
  In the last 12 months, we collected the following categories of personal information, depending on the Services used:
  
  *   Identifiers (like your name, contact information, and device and online identifiers);
  *   Commercial information (your billing information and purchase history, for example);
  *   Characteristics protected by law (for example, you might provide your gender as part of a research survey for us or you may choose to voluntarily disclose your race or veteran status as part of your job application);
  *   Internet or other electronic network activity information (such as your usage of our Services, like the actions you take as an administrator of a WordPress.com site);
  *   Geolocation data (such as your location based on your IP address);
  *   Audio, electronic, visual or similar information (such as your profile picture, if you uploaded one);
  *   Professional or employment-related information; and
  *   Inferences we make (such as likelihood of retention or attrition).
  
  If you are a job applicant, we may have also collected:
  
  *   Education information, such as the education you disclose in your job application.
  
  In some US states you have additional rights subject to any exemptions provided by your state’s respective law, including the right to:
  
  *   Request a copy of the specific pieces of information we collect about you and, if you’re in California, to know the categories of personal information we collect, the categories of business or commercial purpose for collecting and using it, the categories of sources from which the information came, and the categories of third parties we share it with;
  *   Request deletion of personal information we collect or maintain;
  *   Request correction of personal information we collect or maintain;
  *   Opt out of any sale or sharing of personal information; 
  *   Receive a copy of your information in a readily portable format; and
  *   Not receive discriminatory treatment for exercising your rights.
  
  
  #### **_Right to Opt Out_**
  
  We never directly sell your personal information in the conventional sense (i.e., for money).
  
  We may share your information as necessary with our third-party service providers to provide our services to you. To the extent that we share your information with certain advertising, marketing, or analytics vendors, this can be considered a “sale” or “share” in certain U.S. States, which you may have the right to opt out of.
  
  You can learn more about this sharing and how to opt out by clicking the “Do Not Sell My Personal Information” link in the footer of our websites, or from the settings page within our apps. Note that in some instances this link will only display to individuals visiting our sites from within the relevant states.
  
  Our opt-out is managed through cookies, so if you delete cookies, your browser is set to delete cookies automatically after a certain length of time, or if you visit sites in a different browser, you’ll need to make this selection again.
  
  We do not collect or process your sensitive (and potentially sensitive) personal information except where it is strictly necessary to provide you with our service, where the processing is not for the purpose of inferring characteristics about you, or for other purposes that do not require an option to limit under California law. We don’t knowingly sell or share personal information of those under 16.
  
  #### **_Your Information & Personalized Advertising_**
  
  As part of our advertising efforts, we use cookies to share certain device identifiers and information about your browsing activities with our advertising partners, and those advertising partners may use that information to show you personalized ads on some of our users’ sites and some of our own. 
  
  The personal information we share includes online identifiers; internet or other network or device activity (such as cookie information, other device identifiers, and IP address); and geolocation data (approximate location information from your IP address). These disclosures may be considered a “sale” of information under the CCPA. We do not sell (or share) information through our ads program that identifies you personally, like your name or contact information. We don't knowingly sell personal information of those under 16.
  
  #### _Contacting Us About These Rights_
  
  You can usually access, correct, or delete your personal data using your account settings and tools that we offer, but if you aren’t able to or you’d like to contact us about one of the other rights, reach out to us directly at contact@pansophic.co.
  
  When you contact us about one of your rights under this section, we’ll need to verify that you are the right person before we disclose or delete anything. For example, if you are a user, we will need you to contact us from the email address associated with your account. You can also designate an authorized agent to make a request on your behalf by giving us written authorization. We may still require you to verify your identity with us.
  
  #### _Appeals Process for Rights Requests Denials_
  
  In some circumstances we may deny your request to exercise one of these rights. For example, if we cannot verify that you are the account owner we may deny your request to access the personal information associated with your account. As another example, if we are legally required to maintain a copy of your personal information we may deny your request to delete your personal information.
  
  In the event that we deny your request, we will communicate this fact to you in writing. You may appeal our decision by responding in writing to our denial email and stating that you would like to appeal. All appeals will be reviewed by an internal expert who was not involved in your original request. In the event that your appeal is also denied this information will be communicated to you in writing.  Please note that the appeal process does not apply to job applicants.
  
  If your appeal is denied, in some US states (Colorado, Connecticut, and Virginia) you may refer the denied appeal to the state attorney general if you believe the denial is in conflict with your legal rights. The process for how to do this will be communicated to you in writing at the same time we send you our decision about your appeal.
  
  ### How to Reach Us
  
  If you have a question about this Privacy Policy, or you would like to contact us about any other topic, please contact us through our via [email](mailto:privacypolicyupdates@automattic.com).
  
  ### Other Things You Should Know (Keep Reading!)
  
  #### _Transferring Information_
  
  Because Pansophic's Services are offered worldwide, the information about you that we process when you use the Services in the EU may be used, stored, and/or accessed by individuals operating outside the European Economic Area (EEA) who work for us, other members of our group of companies, or third-party data processors. This is required for the purposes listed in the How and Why We Use Information section above. When providing information about you to entities outside the EEA, we will take appropriate measures to ensure that the recipient protects your personal information adequately in accordance with this Privacy Policy as required by applicable law. These measures include entering into European Commission approved standard contractual arrangements with entities based in countries outside the EEA. You can ask us for more information about the steps we take to protect your personal information when transferring it from the EU.
  
  #### _Ads and Analytics Services Provided by Others_
  
  Other parties may also provide analytics services via our Services. These analytics providers may set tracking technologies (like cookies) to collect information about your use of our Services and across other websites and online services. These technologies allow these third parties to recognize your device to compile information about you or others who use your device. This information allows us and other companies to, among other things, analyze and track usage, determine the popularity of certain content, and deliver ads that may be more targeted to your interests. Please note this Privacy Policy only covers the collection of information by Automattic and does not cover the collection of information by any third-party advertisers or analytics providers.
  
  ### Privacy Policy Changes
  
  Although most changes are likely to be minor, Pansophic may change its Privacy Policy from time to time. Pansophic encourages visitors to frequently check this page for any changes to its Privacy Policy. If we make changes, we will notify you by revising the change log below, and, in some cases, we may provide additional notice by sending you a notification through email. Your further use of the Services after a change to our Privacy Policy will be subject to the updated policy.
  
  ### Change log
  
  *   N/A
  `;

  return (
    <div style={{maxWidth: "1000px", width: "80%", margin: "auto"}}>
        <h1 style={{margin: "2rem 0", textAlign: "center"}}>Privacy Policy</h1>
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </div>
  );
}
